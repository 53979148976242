<template>
	<div class="logo-C" :role="$tt('aria_ana_logo')">
		<router-link :to="store.state.lang === 'tr' ? '/' : '/en/'" tabindex="0">
			<span @click="store.state.menuopened = false">
				<img
					src="@/assets/img/logo-tcdd.png"
					v-if="store.state.isdesktop || (store.state.istabletv && !main)"
					:alt="$tt('tcdd')"
				/>
				<img
					src="@/assets/img/logo-tcdd-txt-w.png"
					v-if="store.state.istablet"
					:alt="$tt('tcdd')"
				/>
			</span>
		</router-link>
	</div>
</template>
<script>
import { useStore } from 'vuex'
export default {
	props: {
		main: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const store = useStore()
		return { store }
	},
}
</script>
