<template>
	<teleport to="body">
		<div class="social-share-C" v-if="store.state.shareShow" @click="closeImg" tabindex="0" @keydown="KeyDown($event)">
			<a
				:href="soc.url"
				v-for="(soc, i) in socialMedias"
				:key="i"
				target="_blank"
				tabindex="0"
				:title="soc.title"
			>
				<fa-icon :icon="soc.ico" />
			</a>
		</div>
	</teleport>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const socialMedias = ref([
			{
				url: 'https://twitter.com/share?url=' + window.location.href,
				ico: ['fab', 'twitter'],
				title:"Twitter"
			},
			{
				url:
					'https://www.facebook.com/sharer/sharer.php?u=' +
					window.location.href,
				ico: ['fab', 'facebook-f'],
				title:"Facebook"
			},
			{
				url: 'https://t.me/share/url?url=' + window.location.href,
				ico: ['fab', 'telegram'],
				title:"Telegram"
			},
			{
				url: 'whatsapp://send?text=' + window.location.href,
				ico: ['fab', 'whatsapp'],
				title:"Whatsapp"
			},
			{
				url:
					'https://www.linkedin.com/sharing/share-offsite/?url=' +
					window.location.href,
				ico: ['fab', 'linkedin'],
				title:"Linkedin"
			},
			{
				url: 'mailto:?body=' + window.location.href,
				ico: ['fas', 'envelope'],
				title:"Mail"
			},
		])
		const closeImg = () => {
			store.state.shareShow = false
		}
		const KeyDown = e => {
			if(e.key === 'Enter') closeImg()
		}
		return {
			store,
			closeImg,
			KeyDown,
			socialMedias,
		}
	},
}
</script>
<style>
.social-share-C {
	position: fixed;
	z-index: 99999;
	background-color: #fff;
	display: flex;
	justify-content: center;
	bottom: 0;
	right: 69px;
	align-items: center;
	width: 56px;
	flex-direction: column;
}
.social-share-C a {
	padding: 16px;
}
.social-share-C svg {
	font-size: 24px;
}
@media only screen and (max-width: 768px) {
	.social-share-C {
		right: 0;
	}
}
</style>
