<template>
	<main-slider v-if="loaded" />
	<main-video-area v-if="loaded" />
	<main-map v-if="store.state.ismapok" />
	<main-online v-if="loaded" />
	<main-projects />
	<main-places v-if="loaded" />
	<pop-up
		:data="store.state.maindata.popup.content"
		v-if="loaded && ispop"
		v-model:isactive="ispop"
	/>
</template>
<script>
import { ref, onBeforeMount, watch, inject } from 'vue'
import { useStore } from 'vuex'
import MainSlider from './MainSlider'
import MainVideoArea from './MainVideoArea'
import MainMap from './MainMap'
import MainOnline from './MainOnline'
import MainProjects from './MainProjects'
import MainPlaces from './MainPlaces'
import PopUp from '@/components/PopUp'

export default {
	components: {
		MainSlider,
		MainVideoArea,
		MainMap,
		MainOnline,
		MainProjects,
		MainPlaces,
		PopUp,
	},
	setup() {
		const store = useStore()
		const loaded = ref(false)
		const ispop = ref(false)
		const $tt = inject('$tt')

		onBeforeMount(async () => {
			store.state.maindata = await store.dispatch('postDatas', {
				url: 'main-data',
			})
			if (store.state.maindata.popup) ispop.value = true
			document.title = 'TCDD - ' + $tt('tcdd')
			loaded.value = true
		})
		watch(
			() => store.state.lang,
			async () => {
				loaded.value = false
				store.state.maindata = await store.dispatch('postDatas', {
					url: 'main-data',
				})
				store.state.maindata = Object.assign({}, store.state.maindata)
				loaded.value = true
			}
		)

		return {
			loaded,
			store,
			ispop,
		}
	},
}
</script>
<style>
@media only screen and (max-width: 1600px) {
	.main-project-content-C {
		width: 100%;
		overflow-x: scroll;
	}
	.main-project-item-C {
		padding: 90px 30px;
	}
}
@media only screen and (max-width: 1024px) {
	.main-news-slider-C {
		left: 24px;
		bottom: 24px;
	}
	.bottom-menu-C {
		right: 100px;
		bottom: 24px;
	}
	.main-places-row {
		padding-left: 0 !important;
	}
}
@media only screen and (max-width: 768px) {
	.main-project-content-C {
		overflow-x: scroll;
	}
	.main-slider-content-C {
		overflow: hidden;
	}
	.main-news-slider-C {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 32px;
	}
	.main-news-slider-C:after,
	.main-news-slider-C:before {
		content: none;
	}
	.main-news-slider-inner {
		width: 100%;
		margin-bottom: 44px;
	}
	.news-slider-content {
		width: 100%;
	}
	.news-slider-content span,
	.news-slider-content h3 {
		width: 100%;
	}
	.main-video-row-C .r-container {
		flex-wrap: wrap;
	}
	.main-video-C {
		margin-right: 0;
	}
	.main-video-item {
		width: 100%;
	}
	.main-director-C {
		justify-content: center;
	}
	.main-project-item-C {
		padding: 60px 32px;
		width: 50%;
		flex-shrink: 0;
	}
	.main-project-tab-C {
		flex-wrap: wrap;
	}
	.main-project-tab-C .r-title-C {
		margin-bottom: 32px;
	}
	.main-project-tab-C .tabs-C {
		justify-content: space-between;
	}
	.main-places-C {
		padding: 32px !important;
	}
	.main-place-item {
		width: 100% !important;
	}
	.main-news-all-news-C {
		width: 90%;
	}
}
@media only screen and (max-width: 640px) {
	.main-online-C .r-container {
		flex-wrap: wrap;
	}
	.r-btn.online-btn {
		margin: 0 !important;
		width: 140px !important;
		padding: 12px !important;
	}
	.main-news-slider-C {
		padding: 24px;
	}
	.main-online-C h1 {
		font-size: 32px;
	}
	.online-btn:nth-child(1) {
		margin-right: 10px !important;
		margin-bottom: 10px !important;
	}
	.online-btn:nth-child(2) {
		margin-bottom: 10px !important;
	}
	.online-btn:nth-child(3) {
		margin-right: 10px !important;
	}
	.r-btn.online-btn img {
		max-width: 32px;
	}
	.main-place-item {
		width: 100% !important;
	}
	.main-director-C {
		margin-top: 32px;
	}
	.director-img {
		max-width: 140px;
	}
	.main-project-item-C {
		padding: 60px 32px;
		border-right: none;
		width: 100%;
		flex-shrink: 0;
	}
	.auction-date {
		position: relative !important;
		top: 0 !important;
		right: 0 !important;
	}
}
</style>
