/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import axios from 'axios'
import router from '@/router'

export default createStore({
	state: {
		languages: ['en'],
		apikey: '',
		apiBase: 'https://www.tcdd.gov.tr/api/v1/',
		description: 'Türkiye Cumhuriyeti Devlet Demiryolları Resmî Sitesi',
		keyword: `TCDD, Türkiye Cumhuriyeti Devlet Demiryolları, TCDD Genel Müdürü, Ali İhsan Uygun,
			TCDD Taşımacılık, Tüvasaş, Tüdemsaş, Tülomsaş, Haydarpaşa Liman, Marmaray, İzmir Liman, Haydarpaşa Garı,
			Sirkeci Garı, TCDD tarihçesi, ray, tren, demiryolu, Ankara Demirspor, izban,
			raysimaş, datem, yht, yüksek hızlı tren, milli tren, hızlı tren`,
		lang: '',
		raillifeBaseUrl: 'https://static.tcdd.gov.tr/webfiles/frame/iframe.html?d=',
		generalData: {},
		isdesktop: true,
		isdesksmall: false,
		istablet: false,
		istabletv: false,
		ismobile: false,
		ismapok: true,
		ismuseumsok: false,
		menuopened: false,
		quickopened: false,
		errorapi: false,
		menuloaded: false,
		menus: {},
		submenu: [],
		topmenu: [],
		maindata: {},
		footer: {},
		mapcats: {},
		projectcatid: 0,
		projectlist: [],
		mapmarkurl: '',
		mapSubCat: '',
		mapLayer: null,
		mapPictureCats: [],
		searchShow: false,
		shareShow: false,
		isRouteOk: 'ok',
		newsListData: [],
		newsSelectedYear: null,
		newsCurrentPage: 1,
		newsTotalPage: 1,
		pressListData: [],
		pressSelectedYear: null,
		pressCurrentPage: 1,
		pressTotalPage: 1,
		mainMap:{}
	},
	mutations: {
		changeMenuOpen(state) {
			state.menuopened = !state.menuopened
		},
	},
	actions: {
		getDatas: async ({ state }, api) => {
			const res = await axios
				.get(state.apiBase + api.url, {
					headers: {},
				})
				.then(res => {
					//state.errorapi = false
					return res.data
				})
				.catch(error => {
					state.errorapi = true
					console.log(error)
				})
			return res
		},
		GetUrlDatas: async ({ state }, api) => {
			const res = await axios
				.get(api.url, {
					headers: {},
				})
				.then(res => {
					//state.errorapi = false
					return res.data
				})
				.catch(error => {
					state.errorapi = true
					console.log(error)
				})
			return res
		},
		postDatas: async ({ state, dispatch }, api) => {
			let opts = {
				headers: {},
			}
			let fdata = new FormData()
			fdata.append('lang', state.lang)
			fdata.append('api_key', state.apikey)
			if (api.headers !== undefined) opts.headers = api.headers
			if (api.body !== undefined) opts.body = api.body
			if (api.data !== undefined) {
				Object.keys(api.data).forEach(function(key) {
					fdata.append(key, api.data[key])
				})
				opts.headers['Content-Type'] = 'multipart/form-data'
			}
			const res = await axios
				.post(state.apiBase + api.url, fdata, opts)
				.then(res => {
					//state.errorapi = false
					var lang = state.lang === 'tr' ? '' : state.lang + '/'
					state.isRouteOk = 'ok'
					if (api.content !== undefined && api.content === 'content') {
						if (res.data.msg === '404') {
							if(router.currentRoute._rawValue.meta.name === 'haberler') router.push('/' + lang + 'haberler')
							else if(router.currentRoute._rawValue.meta.name === 'news') router.push('/' + lang + 'news')
							else if(router.currentRoute._rawValue.meta.name === 'muzeler') router.push('/' + lang + 'muzeler')
							else if(router.currentRoute._rawValue.meta.name === 'museums') router.push('/' + lang + 'museums')
							else state.isRouteOk = '404'
						} else if (res.data.msg === '403') {
							state.isRouteOk = '403'
							router.push('/' + lang + '403')
						} else {
							state.isRouteOk = 'ok'
						}
					} else {
						if (
							api.content !== undefined &&
							(api.content === 'list' || api.content === 'other')
						)
							state.isRouteOk = 'ok'
						if (res.data.genel !== undefined && res.data.genel !== null) {
							dispatch('SetHeader', res.data.genel)
							dispatch('SetGeneralData', res.data.genel)
						}
					}
					return res.data
				})
				.catch(error => {
					state.errorapi = true
					console.log(error)
				})
			return res
		},
		openUrl({ state }, u) {
			if (u.link) {
				window.open(u.link, u.target)
			} else {
				var routeto = {}
				var lang = state.lang === 'tr' ? '' : 'en/'
				var root = u.root === undefined || u.root === '' ? '' : u.root
				var isSlug =
					u.slug === undefined ? '' : (root === '' ? '' : '/') + u.slug
				var isType =
					u.type === undefined ? '' : (root === '' ? '' : '/') + u.type

				routeto.path = '/' + lang + root + isSlug + isType

				if (u.meta) {
					var meta = {}
					Object.keys(u.meta).forEach(key => {
						meta[key] = u.meta[key]
					})
					routeto.meta = meta
				}
				if (u.query) {
					routeto.query = u.query
				}
				router.push(routeto)
			}
		},
		SetHeader: ({ state }, res) => {
			if (res.title !== undefined && res.title !== null && res.title !== '')
				document.title = res.title
			else document.title = state.pageTitle
			if (
				res.keyword !== undefined &&
				res.keyword !== null &&
				res.keyword !== ''
			)
				document.getElementsByTagName('meta')['keywords'].content = res.keyword
			else
				document.getElementsByTagName('meta')['keywords'].content =
					state.headKeyword
			if (
				res.description !== undefined &&
				res.description !== null &&
				res.description !== ''
			)
				document.getElementsByTagName('meta')['description'].content =
					res.description
			else
				document.getElementsByTagName('meta')['description'].content =
					state.pageDescription
			if (
				res.favicon !== undefined &&
				res.favicon !== null &&
				res.favicon !== ''
			)
				document.querySelector('link[rel=icon]').href = res.favicon
		},
		SetPageHead: ({ state }, res) => {
			if (
				res.keyword !== undefined &&
				res.keyword !== null &&
				res.keyword !== ''
			)
				document.getElementsByTagName('meta')['keywords'].content = res.keyword

			if (
				res.description !== undefined &&
				res.description !== null &&
				res.description !== ''
			)
				document.getElementsByTagName('meta')['description'].content =
					res.description
		},
		SetGeneralData: ({ state }, res) => {
			if (res.adres !== undefined && res.adres !== null && res.adres !== '')
				state.generalData.adres = res.adres

			if (res.eposta !== undefined && res.eposta !== null && res.eposta !== '')
				state.generalData.eposta = res.eposta

			if (res.fax !== undefined && res.fax !== null && res.fax !== '')
				state.generalData.fax = res.fax

			if (res.tel !== undefined && res.tel !== null && res.tel !== '')
				state.generalData.tel = res.tel

			state.generalData.url = res.url

			if (res.logo !== undefined && res.logo !== null && res.logo !== '')
				state.generalData.logo = res.logo
			else {
				if (state.isdesktop || state.istabletv)
					state.generalData.logo = require('@/assets/img/logo-tcdd.png')
				if (state.istablet)
					state.generalData.logo = require('@/assets/img/logo-tcdd-txt-w.png')
			}
		},
	},
	modules: {},
})
