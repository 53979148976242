<template>
	<router-view />
</template>
<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()

		const checkMobile = () => {
			store.state.ismobile = false
			store.state.istablet = false
			store.state.istabletv = false
			store.state.isdesktop = true
			if (window.innerWidth <= 1600) {
				store.state.isdesksmall = true
			}
			if (window.innerWidth <= 1024) {
				store.state.istablet = true
				store.state.istabletv = true
				store.state.isdesktop = false
			}
			if (window.innerWidth <= 768) {
				store.state.istabletv = false
				store.state.isdesktop = false
			}
			if (window.innerWidth <= 640) {
				store.state.ismobile = true
				store.state.isdesktop = false
				store.state.istablet = true
			}
		}
		onBeforeMount(() => {
			checkMobile()
			store.state.apikey = document.head
				.querySelector('meta[name="api_key"]')
				.getAttribute('content')
			window.onresize = checkMobile()
		})
	},
}
</script>
<style>
#app {
	display: flex;
	width: 100%;
	min-height: 100%;
	flex-wrap: wrap;
}
</style>
