<template>
	<div class="page-search-content-C flx-col">
		<div class="r-row r-nop">
			<div class="r-container page-404 flx-col">
				<h2>{{ $tt('sayfa_bulunamadi') }}</h2>
				<search-box :pos="'opened'" />
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeMount, inject } from 'vue'

export default {
	setup() {
		const $tt = inject('$tt')
		onBeforeMount(async () => {
			document.title = 'TCDD - ' + $tt('p404')
		})
	},
}
</script>
<style>
.page-404 {
	justify-content: center;
	text-align: center;
	align-items: center;
}
</style>
