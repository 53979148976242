<template>
	<div :class="[main ? 'menus-C ' : 'sub-page ', 'top-menu-C']">
		<ul>
			<li>
				<nav-logo
					v-if="store.state.istabletv"
					class="nav-logo-tablet"
					:main="main"
				/>
			</li>
			<li :role="$tt('aria_dil_degistir')">
				<switch-lang />
			</li>
			<template v-if="store.state.isdesktop || store.state.istabletv">
				<template v-if="!main">
					<li v-for="(bot, i) in store.state.menus.bot" :key="i">
						<a :href="bot.url" target="_blank" :title="bot.txt"><img :src="bot.icoo" :alt="bot.txt"/></a>
					</li>
				</template>
				<li v-for="(top, j) in store.state.menus.top" :key="j">
					<a :href="top.url" :title="top.txt"><img :src="main ? top.ico : top.icoo" :alt="top.txt"/></a>
				</li>
			</template>
		</ul>
	</div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SwitchLang from './SwitchLang'
import NavLogo from './NavLogo'

export default {
	components: {
		SwitchLang,
		NavLogo,
	},
	props: {
		main: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const store = useStore()

		const isMain = computed(() => {
			if (props.main) return '-w'
			else return ''
		})

		return {
			isMain,
			props,
			store,
		}
	},
}
</script>
