<template>
	<div
		:class="[
			'flx-col',
			'jc-sbetween',
			store.state.istabletv ? 'r-col-6' : 'r-col-7',
		]"
	>
		<div class="main-video-C r-col-10">
			<div class="main-video-slider" v-if="loaded">
				<span class="txt-stroke">
					{{ String(sliderdata.curr + 1).padStart(2, '0') }}
				</span>
				<swiper
					class="main-video-items-C flx-row"
					:slides-per-view="1"
					:space-between="0"
					@slideChange="OnSlideChange"
					:pagination="{ clickable: true, el: '.video-slide-pagination' }"
					:role="$tt('aria_video_list')"
				>
					<swiper-slide
						v-for="(video, i) in videos.content"
						class="main-video-item flx-col"
						@click="openVideo(video)"
						@keydown="KeyDown($event,video)"
						:key="i"
						:role="$tt('aria_video')"
						aria-haspopup="true"
						:aria-label="video.title !== '' && video.title !== undefined ? video.title : video.txt"
						tabindex="0"
					>
						<div class="main-video-img">
							<img :src="video.img" class="video-img" :alt="video.title !== '' && video.title !== undefined ? video.title : video.txt"/>
							<img
								src="@/assets/img/icp-video-play-button.png"
								class="video-play-btn as-center"
							/>
						</div>
						<h2 v-if="video.title !== '' && video.title !== undefined">{{ video.title }}</h2>
						<p>
							{{ video.txt }}
						</p>
					</swiper-slide>
				</swiper>
			</div>
			<div class="r-row r-nop jc-sbetween r-mt-16">
				<router-link :to="toLink" :title="$tt('tum_videolar')">
					<r-btn :title="$tt('tum_videolar')" pos="bot-right"/>
				</router-link>
				<div class="video-slide-pagination"></div>
			</div>
		</div>
	</div>
	<video-youtube
		:videourl="videourl"
		v-model:isactive="videoactive"
		v-if="videoactive"
	/>
</template>
<script>
import { ref, reactive, onBeforeMount, computed, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue'
import VideoYoutube from '@/components/VideoYoutube'
import SwiperCore, { Pagination } from 'swiper'
SwiperCore.use([Pagination])

export default {
	components: {
		VideoYoutube,
		Swiper,
		SwiperSlide,
	},
	setup() {
		const store = useStore()
		const loaded = ref(false)
		const videos = ref({})
		const videourl = ref('')
		const videoactive = ref(false)
		const $tt = inject('$tt')

		const sliderdata = reactive({
			curr: 0,
		})
		const openVideo = v => {
			if (v.videourl.includes('youtu.be'))
				videourl.value = v.videourl.replace('youtu.be', 'www.youtube.com/embed')
			else videourl.value = v.videourl.replace('watch?v=', 'embed/')

			setTimeout(() => {
				videoactive.value = true
			}, 10)
		}
		const KeyDown = (e,v) => {
			if(e.key === 'Enter') openVideo(v)
		}
		const toLink = computed(() => {
			let url =
				(store.state.lang === 'tr' ? '/' : '/en/') +
				$tt('videolar').toLowerCase()
			return url
		})
		const OnSlideChange = s => {
			sliderdata.curr = s.activeIndex
		}
		onBeforeMount(async () => {
			videos.value = await store.dispatch('postDatas', {
				url: 'video-list',
				data: {
					sortname: 'TARIH',
					sortorder: 'DESC',
					rp: 6,
				},
			})
			loaded.value = true
		})

		watch(
			() => store.state.lang,
			async () => {
				loaded.value = false
				videos.value = await store.dispatch('postDatas', {
					url: 'video-list',
					data: {
						sortname: 'TARIH',
						sortorder: 'DESC',
						rp: 6,
					},
				})
				videos.value = Object.assign({}, videos.value)
				sliderdata.curr = 0
				loaded.value = true
			}
		)

		return {
			loaded,
			videos,
			store,
			sliderdata,
			videourl,
			videoactive,
			openVideo,
			KeyDown,
			toLink,
			OnSlideChange,
		}
	},
}
</script>
<style>
.main-video-C {
	margin-right: 50px;
	position: relative;
	min-height: 370px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.main-video-slider {
	display: flex;
	position: relative;
}
.main-video-item {
	position: relative;
	cursor: pointer;
	flex: 0 0 auto;
}
.main-video-img {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 360px;
	max-width: 100%;
	overflow: hidden;
}
.main-video-img .video-img {
	max-width: 100%;
}
.main-video-slider .txt-stroke {
	position: absolute;
	top: -40px;
	left: 16px;
	z-index: 9;
}
.main-video-img .video-play-btn {
	z-index: 9;
	position: absolute;
}
.main-video-items-C {
	overflow: hidden;
}
.main-video-items-in {
	transition: all 0.4s ease-in-out;
	position: relative;
}
.main-video-items-C .swiper-wrapper {
	display: flex;
}
.video-slide-pagination {
	display: flex;
	padding: 12px;
	justify-content: flex-end;
	align-self: center;
}
.video-slide-pagination .swiper-pagination-bullet {
	background-color: var(--c-txt-grey);
	margin: 0 6px;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	position: relative;
	cursor: pointer;
}
.video-slide-pagination .swiper-pagination-bullet-active {
	background-color: var(--c-primary);
}
.video-slide-pagination .swiper-pagination-bullet-active:before {
	content: '';
	width: 12px;
	height: 12px;
	border: 1px solid var(--c-primary);
	border-radius: 100%;
	position: absolute;
	left: -3px;
	top: -3px;
}
@media only screen and (max-width: 1024px) {
	.main-video-img {
		min-width: inherit;
		min-height: inherit;
	}
}
</style>
