<template>
	<div class="main-map-dist-C r-row r-nop jc-sbetween">
		<div
			class="map-dist-item-C"
			v-for="(dist, i) in store.mapcats.region"
			:key="i"
			@click="store.dispatch('openUrl', dist.url)"
			tabindex="0"
		>
			<div class="map-dist-img">
				<img :src="dist.ico" />
			</div>
			<h4>{{ dist.txt }}</h4>
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()

		return {
			store,
		}
	},
}
</script>
<style>
.map-dist-item-C {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	margin: 0 6px;
}
.map-dist-img {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
