<template>
	<div
		:class="[
			'r-row footer-content-C c-menu',
			store.state.istablet ? 'r-mb-0' : 'r-nop',
		]"
	>
		<div class="r-container r-pt-0 r-pb-16">
			<footer-address :data="store.state.footer.about" />
			<footer-menu />
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'
import FooterAddress from './FooterAddress'
import FooterMenu from './FooterMenu'

export default {
	components: {
		FooterAddress,
		FooterMenu,
	},
	setup() {
		const store = useStore()

		return {
			store,
		}
	},
}
</script>
<style>
.footer-links-C {
	margin-bottom: 32px;
}
.footer-link {
	color: var(--c-menu);
	padding: 6px 16px;
	border-left: 1px solid var(--c-menu);
	width: 100%;
}
.footer-link:hover {
	color: var(--c-white);
	border-left: 2px solid var(--c-white);
}
.footer-link.footer-link-main {
	border-left: none;
	padding: 0 16px 16px 0;
	font-weight: 600;
}
.bottom-logo {
	justify-content: flex-start;
	padding: 10px 0 !important;
	margin: 0 !important;
}
</style>
