<template>
	<teleport to="body">
		<div class="root-popup" @click="CloseMe" @keydown="KeyDown($event)" :role="$tt('aria_popup')">
			<div class="close-btn" @click="CloseMe" @keydown="KeyDown($event)" tabindex="0" :role="$tt('aria_popup_kapat')"
			:aria-label="$tt('aria_kapat')"></div>
			<div class="popup-frame" v-html="data" @click="popClick" tabindex="0"></div>
		</div>
	</teleport>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
	props: {
		data: String,
		isactive: Boolean,
	},
	setup(props, { emit }) {
		const store = useStore()
		const loaded = ref(false)

		const CloseMe = () => {
			emit('update:isactive', false)
		}
		const popClick = e => {
			e.stopPropagation()
		}
		const KeyDown = e => {
			if(e.key === 'Enter') CloseMe()
		}
		onMounted(() => {
			loaded.value = true
		})
		return {
			store,
			loaded,
			CloseMe,
			KeyDown,
			popClick,
		}
	},
}
</script>
<style>
.root-popup {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99999;
	background-color: #000000c9;
	display: flex;
	justify-content: center;
	top: 0;
	left: 0;
	align-items: center;
	padding: 32px 64px;
}
.popup-frame {
	background-color: #fff;
	display: flex;
	border: none;
	max-width: 100%;
	max-height: 100%;
	padding: 16px;
	border-radius: 6px;
	justify-content: center;
	align-items: center;
}
@media only screen and (max-width: 768px) {
	.root-popup {
		padding: 32px;
	}
}
@media only screen and (max-width: 640px) {
	.root-popup {
		padding: 32px 16px;
	}
}
</style>
