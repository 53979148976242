<template>
	<div
		:class="[
			'r-btn',
			pos !== '' ? 'r-btn-' + pos : '',
			shadow ? 'r-btn-shadow' : '',
			light ? 'r-btn-light' : '',
		]"
		:style="styles"
	>
		<img v-if="img" :src="img" />
		<span>{{ title }}</span>
		<slot />
	</div>
</template>
<script>
import { computed } from 'vue'

export default {
	props: {
		title: String,
		url: String,
		pos: {
			type: String,
			default: '',
		},
		img: String,
		bgcolor: String,
		color: String,
		shadow: {
			type: Boolean,
			default: false,
		},
		light: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const styles = computed(() => {
			let style = {}
			if (props.bgcolor) style.backgroundColor = props.bgcolor
			if (props.color) style.color = props.color
			return style
		})
		return {
			styles,
		}
	},
}
</script>
<style>
.r-btn {
	background-color: var(--c-primary);
	padding: 14px 20px;
	color: #fff;
	font-size: 12px;
	border-radius: 44px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	margin: 7px;
	display: flex;
	position: relative;
	flex-shrink: 0;
}
.r-btn-light {
	background-color: var(--c-white);
	color: #58728b;
}
.r-btn-shadow {
	-webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
}
.r-btn img {
	margin-right: 10px;
}
.r-btn:hover,
.r-btn.active {
	background-color: var(--c-primary-red);
}
.r-btn.active:hover{
	background-color: var(--c-primary-red);
}
.r-btn-top-left {
	border-radius: 44px 44px 0 44px;
}
.r-btn-top-right {
	border-radius: 44px 44px 44px 0;
}
.r-btn-bot-left {
	border-radius: 44px 0 44px 44px;
}
.r-btn-bot-right {
	border-radius: 0 44px 44px 44px;
}
.r-btn-light:hover,
.r-btn-light.active {
	background-color: var(--c-primary);
	color: var(--c-white);
}
.r-btn-big{
	font-size: 16px;
}
.r-btn-arrow:after{
	content: "";
	position: relative;
	width: 8px;
	height: 8px;
	border-right: 1px solid #fff;
	border-top: 1px solid #fff;
	transform: rotate(45deg);
	display: inline-block;
	margin-left: 8px;
}
</style>
