<template>
	<div class="select-box-C" @mouseenter="SetZ(1)" @mouseleave="SetZ(0)" :style="'z-index:'+zIndex">
		<div class="select-box-btn-C">
			<slot name="title">
				<span>{{ title }}</span>
				<span class="select-box-arrow"></span>
			</slot>
		</div>
		<div class="select-box-list-C">
			<template v-for="(item, i) in list" :key="i">
				<slot name="item" :item="item">
					<div class="select-box-list-item">
						<span>{{ item }}</span>
					</div>
				</slot>
			</template>
		</div>
	</div>
</template>
<script>
import { ref } from 'vue'
export default {
	props: {
		title: String,
		list: Array,
	},
	setup() {
		const zIndex = ref(4)
		const SetZ = i => {
			if(i === 1) zIndex.value = 9
			else zIndex.value = 4
		}
		return {
			SetZ,
			zIndex
		}
	},
}
</script>
<style>
.select-box-C {
	display: flex;
	flex-direction: column;
	position: relative;
	margin: 10px;
	flex: 1 1 auto;
	z-index: 4;
}
.select-box-C:first-child {
	z-index: 9;
}
.select-box-C:last-child {
	z-index: 2;
}
.select-box-btn-C {
	justify-content: flex-start;
	align-items: center;
	position: relative;
	width: 100%;
	cursor: pointer;
	border: 1px solid #cccccc;
	padding: 16px;
	border-radius: 12px;
	background-color: #fff;
	min-width: 220px;
}
.select-box-C .select-box-btn-C:hover {
	border-bottom: none;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.select-box-arrow {
	width: 12px;
	height: 12px;
	border-right: 2px solid #aaa;
	border-bottom: 2px solid #aaa;
	position: absolute;
	right: 16px;
	transform: rotate(45deg);
}
.select-box-list-C {
	border-radius: 0 0 12px 12px;
	background-color: #fff;
	min-width: 200px;
	width: 100%;
	top: 100%;
	border: 1px solid #cccccc;
	border-top: 0;
	visibility: hidden;
	flex-direction: row;
	position: absolute;
	flex-wrap: wrap;
}
.select-box-C:hover .select-box-list-C {
	display: flex;
	visibility: visible;
}
.select-box-list-item {
	padding: 12px;
	cursor: pointer;
	text-align: center;
}
.select-box-list-item:hover {
	background-color: #ececec;
}
</style>
