/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/Main/MainPage.vue'
import RootPage from '@/RootPage'
import SubPageRoot from '@/pages/SubPage/SubPageRoot.vue'
import Page404 from '@/pages/SubPage/404.vue'
import Page403 from '@/pages/SubPage/403.vue'
import store from '@/store'
import routeen from './en'

const routes = [
	{
		path: '/',
		name: 'RootPage Tr',
		component: RootPage,
		meta: { type: 'main', name: 'main', lang: 'tr' },
		beforeEnter: () => {
			store.state.lang = 'tr'
		},
		children: [
			{
				path: '/',
				name: 'MainPage',
				component: MainPage,
				meta: { type: 'main', name: 'main', lang: 'tr' },
			},			
			{
				path: '/sayfa',
				name: 'sayfamain',
				component: SubPageRoot,
				redirect: '/404',
				meta: { type: 'main', name: 'sayfa', lang: 'tr', title: 'TCDD' },
				children: [
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "sayfa" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: { type: 'sub', name: 'sayfa', lang: 'tr', title: 'TCDD' },
						children: [
							{
								path: ':type',
								component: () =>
									import(
										/* webpackChunkName: "sayfa" */ '@/pages/SubPage/PageSub.vue'
									),
								meta: {
									type: 'sub',
									name: 'sayfa',
									lang: 'tr',
									title: 'TCDD',
								},
							},
						],
					},
				],
			},
			{
				path: '/haberler',
				name: 'haberler',
				component: SubPageRoot,
				meta: { type: 'main', name: 'haberler', lang: 'tr', title: 'Haberler' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "haberler" */ '@/pages/SubPage/News/NewsList.vue'
							),
						meta: {
							type: 'main',
							name: 'haberler',
							lang: 'tr',
							title: 'Haberler',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "haberler" */ '@/pages/SubPage/News/NewsPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'haberler',
							lang: 'tr',
							title: 'Haberler',
						},
					},
				],
			},
			{
				path: '/duyurular',
				name: 'duyurular',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'duyurular',
					lang: 'tr',
					title: 'Duyurular',
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "duyurular" */ '@/pages/SubPage/Announce/AnnounceList.vue'
							),
						meta: {
							type: 'main',
							name: 'duyurular',
							lang: 'tr',
							title: 'Duyurular',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "duyurular" */ '@/pages/SubPage/Announce/AnnouncePage.vue'
							),
						meta: {
							type: 'sub',
							name: 'duyurular',
							lang: 'tr',
							title: 'Duyurular',
						},
					},
				],
			},
			{
				path: '/basin-odasi',
				name: 'basin-odasi',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'basin-odasi',
					lang: 'tr',
					title: 'Basın Odası',
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "basinodasi" */ '@/pages/SubPage/Press/PressList.vue'
							),
						meta: {
							type: 'main',
							name: 'basin-odasi',
							lang: 'tr',
							title: 'Basın Odası',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "basinodasi" */ '@/pages/SubPage/Press/PressPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'basin-odasi',
							lang: 'tr',
							title: 'Basın Odası',
						},
					},
				],
			},
			{
				path: '/projeler',
				name: 'projeler',
				component: SubPageRoot,
				meta: { type: 'main', name: 'projeler', lang: 'tr', title: 'Projeler' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "projeler" */ '@/pages/SubPage/Project/ProjectsList.vue'
							),
						meta: {
							type: 'main',
							name: 'projeler',
							lang: 'tr',
							title: 'Projeler',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "projeler" */ '@/pages/SubPage/Project/ProjectPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'projeler',
							lang: 'tr',
							title: 'Projeler',
						},
					},
				],
			},
			{
				path: '/kurumsal',
				name: 'kurumsal',
				component: SubPageRoot,
				redirect: '/404',
				meta: { type: 'main', name: 'kurumsal', lang: 'tr', title: 'Kurumsal' },
				children: [
					{
						path: 'organizasyon-semasi',
						component: () =>
							import(
								/* webpackChunkName: "kurumsal" */ '@/pages/SubPage/OrganizationChart/PageOrganizationChart.vue'
							),
						meta: {
							type: 'sub',
							name: 'kurumsal',
							lang: 'tr',
							title: 'Kurumsal',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "kurumsal" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'kurumsal',
							lang: 'tr',
							title: 'Kurumsal',
						},
					},
				],
			},
			{
				path: '/sebeke-bildirimi',
				name: 'sebeke-bildirimi',
				redirect: '/404',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'sebeke-bildirimi',
					lang: 'tr',
					title: 'Şebeke Bildirimi',
				},
				children: [
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "sebekebildirimi" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'sebeke-bildirimi',
							lang: 'tr',
							title: 'Şebeke Bildirimi',
						},
					},
				],
			},
			{
				path: '/ihaleler',
				name: 'ihaleler',
				component: SubPageRoot,
				meta: { type: 'main', name: 'ihaleler', lang: 'tr', title: 'İhaleler' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "ihaleler" */ '@/pages/SubPage/Auction/AuctionList.vue'
							),
							redirect: '/ihaleler/0',
						meta: {
							type: 'main',
							name: 'ihaleler',
							lang: 'tr',
							filter: '',
							filterkey: '',
							title: 'İhaleler',
						},
						children: [
							{
								path: ':ihaletype',
								component: () =>
									import(
										/* webpackChunkName: "ihaleler" */ '@/pages/SubPage/Auction/AuctionList.vue'
									),
								meta: {
									type: 'main',
									name: 'ihaleler',
									lang: 'tr',
									filter: '',
									filterkey: '',
									title: 'İhaleler',
								},
							},
						]
					},
					{
						path: 'ihale/:slug',
						component: () =>
							import(
								/* webpackChunkName: "ihaleler" */ '@/pages/SubPage/Auction/AuctionPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'ihaleler',
							lang: 'tr',
							title: 'İhaleler',
						},
					},
				],
			},
			{
				path: '/iletisim',
				name: 'iletisim',
				component: SubPageRoot,
				meta: { type: 'main', name: 'iletisim', lang: 'tr', title: 'İletişim' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "iletisim" */ '@/pages/SubPage/Contact/PageContact.vue'
							),
						meta: {
							type: 'sub',
							name: 'iletisim',
							lang: 'tr',
							title: 'İletişim',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "iletisim" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'iletisim',
							lang: 'tr',
							title: 'İletişim',
						},
					},
				],
			},
			{
				path: '/videolar',
				name: 'videolar',
				component: SubPageRoot,
				meta: { type: 'main', name: 'videolar', lang: 'tr', title: 'Videolar' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "videolar" */ '@/pages/SubPage/Video/PageVideoList.vue'
							),
						meta: {
							type: 'sub',
							name: 'videolar',
							lang: 'tr',
							title: 'Videolar',
						},
					},
				],
			},
			{
				path: '/raillife',
				name: 'raillifetr',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'raillife',
					lang: 'tr',
					title: 'Raillife',
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "raillifetr" */ '@/pages/SubPage/Raillife/RaillifeList.vue'
							),
						meta: {
							type: 'sub',
							name: 'raillife',
							lang: 'tr',
							title: 'Raillife',
						},
					},
				],
			},
			{
				path: '/harita',
				name: 'harita',
				component: () =>
					import(/* webpackChunkName: "harita" */ '@/pages/Map/MapMain.vue'),
				meta: { type: 'main', name: 'harita', lang: 'tr', title: 'Harita' },
				children: [
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "harita" */ '@/pages/Map/MapMain.vue'
							),
						meta: { type: 'sub', name: 'harita', lang: 'tr', title: 'Harita' },
						children: [
							{
								path: ':subcat',
								component: () =>
									import(
										/* webpackChunkName: "harita" */ '@/pages/Map/MapMain.vue'
									),
								meta: {
									type: 'sub',
									name: 'harita',
									lang: 'tr',
									title: 'Harita',
								},
							},
						],
					},
				],
			},
			{
				path: '/muzeler',
				name: 'muzeler',
				component: SubPageRoot,
				meta: { type: 'main', name: 'muzeler', lang: 'tr', title: 'Müzeler' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "muzeler" */ '@/pages/SubPage/Museum/MuseumsList.vue'
							),
						meta: {
							type: 'main',
							name: 'muzeler',
							lang: 'tr',
							title: 'Müzeler',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "muzeler" */ '@/pages/SubPage/Museum/MuseumPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'muzeler',
							lang: 'tr',
							title: 'Müzeler',
						},
					},
				],
			},
			{
				path: '/arama',
				name: 'arama',
				component: SubPageRoot,
				meta: { type: 'main', name: 'arama', lang: 'tr', title: 'Arama' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "arama" */ '@/pages/SubPage/Search/PageSearch.vue'
							),
						meta: { type: 'sub', name: 'arama', lang: 'tr', title: 'Arama' },
						props: r => ({ qu: r.query.q }),
					},
				],
			},
			{
				path: '/',
				name: 'tumsayfalar',
				component: SubPageRoot,
				meta: { type: 'main', name: 'sayfa', lang: 'tr', title: 'TCDD' },
				children: [
					{
						path: ':slug+',
						component: () =>
							import(
								/* webpackChunkName: "tumsayfalar" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'sayfa',
							lang: 'tr',
							title: 'TCDD',
						},
					},
				],
			},
			{
				path: '/',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: '404',
					lang: 'tr',
					title: '404 - Sayfa bulunamadı',
				},
				children: [
					{
						path: '404',
						component: Page404,
						meta: {
							type: 'main',
							name: '404',
							lang: 'tr',
							title: '404 - Sayfa bulunamadı',
						},
					},
				],
			},
			{
				path: '/',
				component: SubPageRoot,
				meta: {
					lang: 'tr',
					type: 'main',
					name: '403 - Bu sayfaya erişim izniniz bulunmuyor.',
				},
				children: [
					{
						path: '403',
						component: Page403,
						meta: {
							lang: 'tr',
							type: 'main',
							name: '403 - Bu sayfaya erişim izniniz bulunmuyor.',
						},
					},
				],
			},
		],
	},
	...routeen,
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0, 'scroll-behavior': 'smooth' }
		}
	},
})

router.beforeEach((to, from, next) => {
	store.state.menuopened = false
	document.title = 'TCDD'
	var splittedRoute = to.fullPath.split('/')
	store.state.languages.forEach(itm => {
		if (itm === splittedRoute[1]) {
			store.state.lang = itm
		}
	})
	if (store.state.lang === undefined || store.state.lang === '') {
		store.state.lang = 'tr'
	}

	if (to.matched.length === 0) {
		store.state.isRouteOk = '404'
	} else {
		if (from.meta.lang !== to.meta.lang) {
			store.state.newsListData = []
			store.state.newsSelectedYear = null
			store.state.newsCurrentPage = 1
			store.state.newsTotalPage = 1
			store.state.pressListData = []
			store.state.pressSelectedYear = null
			store.state.pressCurrentPage = 1
			store.state.pressTotalPage = 1
		}
		if (to.meta.name !== 'haberler' && to.meta.name !== 'news') {
			store.state.newsListData = []
			store.state.newsSelectedYear = null
			store.state.newsCurrentPage = 1
			store.state.newsTotalPage = 1
		}
		if (to.meta.name !== 'basin-odasi' && to.meta.name !== 'press-room') {
			store.state.pressListData = []
			store.state.pressSelectedYear = null
			store.state.pressCurrentPage = 1
			store.state.pressTotalPage = 1
		}
	}
	next()
})

export default router
