<template>
	<nav-quick-icon v-if="store.state.istablet" />
	<nav-logo v-if="store.state.istablet" />
	<nav-menu-icon />
	<div
		class="main-menu-bottom-C"
		v-if="store.state.menuloaded && !store.state.istablet"
	>
		<nav-social :data="store.state.menus.socials" />
		<nav-right-share />
	</div>
</template>
<script>
import { useStore } from 'vuex'
import NavMenuIcon from './NavMenuIcon'
import NavSocial from './NavSocial'
import NavLogo from './NavLogo'
import NavQuickIcon from './NavQuickIcon'
import NavRightShare from './NavRightShare'
export default {
	components: {
		NavMenuIcon,
		NavSocial,
		NavRightShare,
		NavLogo,
		NavQuickIcon,
	},
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
