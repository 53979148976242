<template>
	<div class="r-col-3 flx-col footer-col m-col-12">
		<img src="@/assets/img/logo-tcdd-w.png" alt="TCDD Logo" title="TCDD Logo"/>
		<h4 class="c-menu">T.C. DEVLET DEMİRYOLLARI İŞLETMESİ GENEL MÜDÜRLÜĞÜ</h4>
		<address>
			{{ data.address }}
		</address>
		<nav-social />
		<span>© {{ year.getFullYear() }} TCDD</span>
		<div class="footer-links-C logo-links flx-col" v-if="copyright">
			<a href="#" class="footer-link no-border">Gizlilik Politikası</a>
			<a href="#" class="footer-link no-border">
				Web Sitesi Hüküm ve Koşulları
			</a>
		</div>
		<switch-lang />
	</div>
</template>
<script>
import { ref } from 'vue'
import NavSocial from '../Menu/NavSocial'
import SwitchLang from '../Menu/SwitchLang.vue'

export default {
	components: {
		NavSocial,
		SwitchLang,
	},
	props: {
		data: Object,
	},
	setup() {
		const year = ref(new Date())
		const copyright = ref(false)

		return {
			year,
			copyright,
		}
	},
}
</script>
<style>
.footer-col {
	align-items: flex-start;
	padding-right: 40px;
	padding-top: 72px;
}
.footer-content-C .social-menu-C {
	display: flex;
	margin: 24px 0;
}
.footer-content-C .social-menu-C li {
	margin-right: 24px;
	cursor: pointer;
}
.social-menu-C li a {
	min-width: 32px;
	text-align: center;
}
.logo-links .footer-link {
	padding: 6px 0;
}
.footer-content-C address {
	font-style: normal;
}
.footer-content-C .lang-btn {
	color: #fff;
	margin-top: 24px;
}
</style>
