<template>
	<div class="map-menu-content-C">
		<search-box :pos="'map'" :placeholder="$tt('harita_ara_placeholder')" />
		<div class="map-menu-title">
			<h1>
				<span class="c-red">{{ $tt('ana_kesfet') }}</span>
				<span v-html="$tt('demiryollari_harita_goruntule')"></span>
			</h1>
			<span class="map-menu-comment">
				TCDD Haritasını kullanarak arama yapabilir ve aşağıdaki verileri
				görüntüleyebilirsiniz.
			</span>
		</div>
		<div class="map-menu-buttons-C" :role="$tt('aria_harita_kategoriler')">
			<div
				class="map-menu-button-C"
				v-for="(btn, i) in store.state.mapcats.cats"
				:key="i"
				@click="GetMapData(btn)"
				@keydown="GetMapDataKey($event, btn)"
				:role="$tt('aria_harita_kategori')"
				tabindex="0"
			>
				<div
					:class="[
						'map-menu-button',
						btn.slug === route.params.id ? 'selected' : '',
					]"
				>
					<div :class="['map-menu-button-ico']">
						<img :src="btn.ico" :alt="btn.title" />
					</div>
					<span>{{ btn.title }}</span>
				</div>
			</div>
		</div>
		<div
			class="map-sub-menu-C"
			v-if="showSubMenu"
			:role="$tt('aria_harita_fotograf_kategoriler')"
		>
			<div class="map-sub-menu-close" @click="showSubMenu = false"></div>
			<div
				class="map-sub-menu"
				v-for="(sub, i) in store.state.mapPictureCats"
				:key="i"
				@click="OpenMapSubMenu(sub)"
				@keydown="OpenSubMenuKey($event, sub)"
				tabindex="0"
				:role="$tt('aria_harita_fotograf_kategori')"
				:aria-label="sub.Pin"
			>
				{{ sub.Pin }}
			</div>
		</div>
	</div>
</template>
<script>
import { onBeforeMount, inject, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import router from '@/router'

export default {
	setup() {
		const route = new useRoute()
		const store = useStore()
		const $tt = inject('$tt')
		const CreateSlug = inject('CreateSlug')
		const showSubMenu = ref(false)
		const firstLoad = ref(false)

		const GetMapData = d => {
			if (d.slug !== CreateSlug($tt('fotograflar'))) {
				showSubMenu.value = false
				if (store.state.istablet) {
					store.state.quickopened = false
				}
				var lang = store.state.lang === 'tr' ? '' : store.state.lang + '/'
				let id = d.slug || ''
				router.push('/' + lang + CreateSlug($tt('harita')) + '/' + id)
				store.state.mapmarkurl = d.api
			} else {
				if (!firstLoad.value) {
					showSubMenu.value = !showSubMenu.value
				}
				firstLoad.value = false
			}
		}
		const GetMapDataKey = (e, b) => {
			if (e.key === 'Enter') GetMapData(b)
		}
		const GetMapCatList = async () => {
			let list = await store.dispatch('GetUrlDatas', {
				url: 'https://www.tcdd.gov.tr/apimap',
				content: 'other',
			})
			if (list !== undefined) {
				let filteredList = list.filter(itm => {
					return itm.Dil === store.state.lang || itm.Dil === '*'
				})
				return filteredList
			} else {
				return []
			}
		}
		const OpenMapSubMenu = m => {
			store.state.mapSubCat = m.Id
			var lang = store.state.lang === 'tr' ? '' : store.state.lang + '/'
			router.push(
				'/' +
					lang +
					CreateSlug('harita') +
					'/' +
					CreateSlug($tt('fotograflar')) +
					'/' +
					CreateSlug(m.Pin)
			)
			showSubMenu.value = false
			if (store.state.istablet) {
				store.state.quickopened = false
			}
		}
		const OpenSubMenuKey = (e, m) => {
			if (e.key === 'Enter') OpenMapSubMenu(m)
		}
		onBeforeMount(async () => {
			store.state.mapcats = await store.dispatch('postDatas', {
				url: 'map-cats',
			})
			store.state.mapPictureCats = await GetMapCatList()
			if (route.params.id) {
				if (route.params.subcat === null || route.params.subcat === undefined)
					firstLoad.value = false
			}
		})
		return {
			store,
			route,
			GetMapData,
			GetMapDataKey,
			showSubMenu,
			OpenMapSubMenu,
			OpenSubMenuKey,
		}
	},
}
</script>
<style>
.map-menu-content-C {
	width: 100%;
	padding: 32px;
	overflow-y: auto;
}

.map-menu-title {
	text-align: left;
}

.map-menu-comment {
	color: #737b84;
	font-size: 12px;
}

.map-menu-buttons-C {
	display: flex;
	flex-wrap: wrap;
	padding-top: 24px;
}

.map-menu-button-C {
	width: 25%;
	flex: 0 0 25%;
	font-size: 12px;
	text-align: center;
	padding: 8px;
}

.map-menu-button {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.map-menu-button:hover .map-menu-button-ico,
.map-menu-button.selected .map-menu-button-ico {
	background-color: #006196;
}

.map-menu-button-ico {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	background-color: #758289;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
}

.map-search .search-box-inp-C .search-inp {
	min-width: inherit;
}

.map-sub-menu-C {
	position: absolute;
	z-index: 999;
	left: 100%;
	bottom: 0;
	background-color: #fff;
	min-width: 240px;
	display: flex;
	flex-direction: column;
	text-align: left;
	border-left: 2px solid var(--c-primary);
	-webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
}

.map-sub-menu {
	padding: 16px;
	cursor: pointer;
}

.map-sub-menu:hover {
	background-color: #ededed;
}
.map-sub-menu-close {
	width: 32px;
	height: 32px;
	position: absolute;
	display: none;
	justify-content: center;
	align-items: center;
	right: 8px;
	cursor: pointer;
}
.map-sub-menu-close:before,
.map-sub-menu-close:after {
	content: '';
	width: 20px;
	height: 2px;
	position: absolute;
	transform: rotate(45deg);
	background-color: #000;
	z-index: 99;
}
.map-sub-menu-close:after {
	transform: rotate(-45deg);
}
@media only screen and (max-width: 1024px) {
	.map-sub-menu-C {
		overflow-y: auto;
		height: 100%;
	}
	.map-sub-menu-close {
		display: flex;
	}
}
@media only screen and (max-width: 480px) {
	.map-sub-menu-C {
		left: 0;
	}
}
</style>
