<template>
	<div id="menu-C">
		<navi />
	</div>
	<div class="main-content-C">
		<router-view />
	</div>
	<footer-main />
</template>
<script>
import { useStore } from 'vuex'
import Navi from './pages/Menu/Nav'
import FooterMain from './pages/Footer/FooterMain'
export default {
	components: {
		Navi,
		FooterMain,
	},
	setup() {
		const store = useStore()

		return {
			store,
		}
	},
}
</script>
<style>
#menu-C {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999;
	pointer-events: none;
}
.main-content-C {
	width: 100%;
	min-height: 100vh;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
}
</style>
