<template>
	<top-menu :main="false" v-if="show" />
	<div :class="['sub-page-C', nopclass]">
		<page-404 v-if="store.state.isRouteOk === '404'" />
		<router-view />
	</div>
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import TopMenu from '@/pages/Menu/TopMenu'
import Page404 from '@/pages/SubPage/404.vue'
export default {
	components: {
		TopMenu,
		Page404,
	},
	setup() {
		const route = useRoute()
		const store = useStore()
		const show = computed(() => {
			if (route.meta.type === 'sub') {
				return true
			} else {
				if (route.meta.name === 'muzeler' || route.meta.name === 'museums')
					return false
				else return true
			}
		})
		const nopclass = computed(() => {
			if (route.meta.type === 'sub') {
				return ''
			} else {
				if (route.meta.name === 'muzeler' || route.meta.name === 'museums')
					return 'r-nop'
				else return ''
			}
		})
		return {
			route,
			show,
			nopclass,
			store,
		}
	},
}
</script>
<style>
.sub-page-C {
	width: 100%;
	padding: 90px 66px 90px 134px;
	margin: 0;
	background-color: #f5f5f5;
	min-height: 100vh;
}
.page-title-C {
	display: flex;
	position: relative;
	z-index: 99;
}
.page-title-C.page-title-sub {
	padding-left: 16.66666667% !important;
	justify-content: flex-start;
}
.page-title-inner-C {
	align-items: center;
	justify-content: flex-end;
	z-index: 9;
}
.back-to-all {
	font-size: 12px;
	text-decoration: underline;
	color: var(--c-primary);
	flex-shrink: 0;
	font-weight: 600;
	cursor: pointer;
}
.back-to-all:hover {
	color: var(--c-primary-red);
}
.page-text-content-C {
	display: flex;
	padding-bottom: 120px;
}
.page-date-C {
	color: var(--c-primary-red);
	font-size: 12px;
	width: 100%;
}
.page-text-content-in {
	margin-top: 16px;
	width: 100%;
}
.page-text-content-in h2 {
	width: 50%;
}
.page-text-content-in.r-col-8 h2,
.page-text-content-in.r-col-8 .page-text {
	width: 80%;
}
.page-content {
	width: 100%;
	position: relative;
}
.project-list-item-C {
	background-color: var(--c-white);
	width: 48%;
	border-bottom-right-radius: 44px;
	display: flex;
	margin-bottom: 44px;
	position: relative;
	margin-right: 2%;
}
.project-list-item-C:nth-child(even) {
	margin-right: 0;
}
.project-list-item-C:hover {
	background-color: var(--c-primary);
	color: var(--c-white);
}
.project-list-item-C:hover h3,
.project-list-item-C:hover p {
	color: var(--c-white);
}
.project-list-item-C:hover .r-btn {
	background-color: var(--c-white);
	color: var(--c-primary);
}
.project-list-item-C:hover .r-btn:hover {
	background-color: var(--c-primary-red);
	color: var(--c-white);
}
.project-list-content-C {
	padding: 44px;
	width: 100%;
	align-items: flex-start;
}
.project-list-content-C p {
	margin-bottom: 32px;
}
.page-text-content-C.page-museum {
	padding-left: 0;
	position: relative;
}
.page-museum-top-C {
	min-height: 360px;
}
.page-top-content-img-C {
	max-width: 760px;
	position: relative;
	top: 72px;
	width: 46%;
	z-index: 9;
	min-height: 450px;
}
.page-top-content-img-C img {
	width: 100%;
}
.page-museum-address {
	background-color: #00509fa6;
	color: var(--c-white);
	padding: 60px 44px 60px 130px;
	border-bottom-right-radius: 44px;
	margin-bottom: 24px;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	max-width: 520px;
}
.page-museum-address span {
	margin-bottom: 10px;
}
.r-btn.show-on-map {
	background-color: transparent;
	border: 1px solid #fff;
	border-radius: 20px;
	border-top-left-radius: 0;
	font-size: 14px;
	padding: 10px 20px;
	width: 220px;
	font-weight: 600;
	display: flex;
	margin-top: 12px;
	flex-shrink: 0;
}
.page-museum-top-right-C {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	position: absolute;
	right: 0;
	z-index: 9;
	align-items: flex-start;
	min-height: 400px;
}
.go-gallery-btn {
	margin-left: 130px;
}
.btn-360-C {
	padding: 10px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-top: 32px;
	margin-left: 130px;
}
.btn-360-C img {
	margin-right: 10px;
}
.btn-360-C:hover {
	background-color: rgb(233, 233, 233);
}
.page-text {
	width: 50%;
}
.page-text-content-C.page-news {
	margin-left: 16.6666667%;
}
.page-news-img {
	margin: 32px 0;
	width: 100%;
}
.page-news-img img {
	width: 100%;
}
.news-galleryin {
	position: absolute;
	top: 0;
	right: 0;
	padding-left: 32px;
}
.news-list-item-C {
	background-color: var(--c-white);
	width: 100%;
	padding: 44px;
	border-bottom-right-radius: 44px;
	display: flex;
	margin-bottom: 44px;
	position: relative;
	word-break: break-word;
}
.news-list-item-C:hover {
	background-color: var(--c-primary);
	color: var(--c-white);
}
.news-list-item-C:hover h3,
.news-list-item-C:hover p {
	color: var(--c-white);
}
.news-list-item-C:hover .r-btn {
	background-color: var(--c-white);
	color: var(--c-primary);
}
.news-list-item-C:hover .r-btn:hover {
	background-color: var(--c-primary-red);
	color: var(--c-white);
}
.news-list-img-C {
	width: 220px;
	height: 150px;
	overflow: hidden;
	margin-right: 44px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.news-list-img-C img{
	max-width: 100%;
}
.news-item-date {
	position: absolute;
	right: 20px;
	top: 20px;
	color: #9fafcb;
	font-size: 12px;
}
@media only screen and (max-width: 1024px) {
	.page-text-content-C {
		padding-bottom: 32px;
	}
	.sub-page-C {
		padding: 100px 32px 60px 32px;
	}
	.page-text-content-C.page-news {
		margin-left: 4% !important;
	}
	.page-text-content-in h2 {
		width: 100%;
	}
	.page-text {
		width: 100%;
	}
	.news-galleryin {
		position: relative;
		margin-top: 64px;
		width: 100% !important;
		padding-left: 0;
	}
}
@media only screen and (max-width: 768px) {
	.sub-page-C {
		padding: 120px 24px 60px 24px;
	}
	.sub-page-C .r-row {
		padding: 0;
	}
	.page-title-C.page-title-sub {
		padding-left: 0 !important;
	}
	.menu-box-C .menu-box-txt-C {
		width: auto;
		padding: 0;
	}
	.menu-box-sub-C {
		min-width: 260px;
		left: auto !important;
		right: 0;
	}
	.page-text-content-C {
		padding-left: 0 !important;
	}
	.page-top-content-C {
		margin-right: 0 !important;
	}
	.page-top-content-img-C {
		width: 100% !important;
		top: 0 !important;
		min-height: initial;
	}
	.page-top-content-txt-C {
		padding: 20px !important;
		width: 50%;
		position: relative !important;
		left: 0 !important;
	}
	.project-gallery-C img.active {
		width: 100% !important;
	}
	.page-text-content-C.page-news {
		margin-left: 0 !important;
	}
	.project-list-item-C {
		flex-wrap: wrap;
	}
	.project-list-img-C {
		width: 100% !important;
		height: 200px;
	}
	.project-list-content-C {
		padding: 32px !important;
	}
	.page-museum-top-C .r-container {
		flex-direction: column;
	}
	.page-museum-top-right-C {
		position: relative;
		width: 100%;
		min-height: initial;
	}
	.page-museum-address {
		width: 100%;
		max-width: initial;
		padding: 32px;
		margin-top: 0;
	}
}
@media only screen and (max-width: 640px) {
	.news-list-item-C {
		flex-wrap: wrap;
		padding: 40px 16px 16px 16px !important;
	}
	.news-list-img-C {
		margin-right: 0 !important;
		margin-bottom: 16px;
		width: 100%;
	}
	.news-item-date {
		right: auto !important;
	}
	.page-top-content-C {
		flex-wrap: wrap;
	}
	.page-top-content-img-C,
	.page-top-content-txt-C {
		width: 100% !important;
	}
	.page-text-content-C {
		max-width: 100% !important;
	}
	.page-text-content-in {
		max-width: 100% !important;
		padding-right: 0 !important;
	}
	.page-menu-C {
		flex-wrap: wrap;
	}
	.page-menu-C .r-btn {
		width: 100%;
	}
	.project-list-item-C {
		width: 100% !important;
		margin-right: 0 !important;
	}
	.page-museum-top-C .r-container {
		flex-direction: column;
	}
	.page-museum-top-right-C {
		position: relative !important;
		min-height: inherit !important;
	}
	.page-museum-address {
		padding: 32px !important;
	}
	.page-title-inner-C {
		flex-wrap: wrap;
	}
	.btn-360-C {
		bottom: auto;
		top: calc(100% + 24px);
		position: absolute;
		left: 0;
		background-color: transparent;
		margin: 0;
	}
	.go-gallery-btn {
		margin-left: 0;
	}
}
</style>
