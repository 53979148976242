<template>
	<div class="main-news-slider-C">
		<div class="main-news-slider-inner">
		<swiper
			class="main-news-slider-items-C flx-row"
			:slides-per-view="1"
			:space-between="0"
			:pagination="{ clickable: true, el: '.news-slide-pagination' }"
			:role="$tt('aria_haber_listesi')"
		>
			<swiper-slide
				v-for="(news, i) in newsuse.news.content"
				:key="i"
				:role="$tt('aria_video')"
				:aria-label="
					news.title !== '' && news.title !== undefined
						? news.title
						: news.txt
				"
				tabindex="0"
			>
				<router-link
				class="main-news-slider-item flx-row"
					:to="CreateLink(news.url, 'haberler')"
					:role="$tt('aria_haber')"
					:title="news.title"
				>
					<div class="news-slider-img" v-if="news.img && !store.state.ismobile">
						<img :src="news.img" :alt="news.title" />
					</div>
					<div class="news-slider-content">
						<span class="news-slider-date">
							{{ news.date }}
						</span>
						<h3 class="c-white" :style="{ width: textW(news) }">
							{{ news.title }}
						</h3>
						<span
							class="news-slider-text"
							:style="{ width: textW(news) }"
							v-html="news.short"
						></span>
					</div>
				</router-link>
			</swiper-slide>
		</swiper>
		</div>
		<div class="main-news-all-news-C">
			<div class="main-news-all-btn">
				<router-link
					:to="CreateLink('', 'haberler')"
					class="back-to-all"
					:title="$tt('tum_haberler')"
				>
					{{ $tt('tum_haberler') }} »
				</router-link>
			</div>
			<div class="news-slide-pagination"></div>
		</div>
	</div>
</template>
<script>
import { ref, reactive, onBeforeMount, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination } from 'swiper'
SwiperCore.use([Pagination])
export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		const store = useStore()
		const loaded = ref(false)
		var sliderTimeout = null
		const newsuse = reactive({
			news: {},
			newsid: 0,
			ww: 620,
		})
		const sliderdata = reactive({
			imgcw: 0,
			imgC: null,
			curr: 0,
			bigimg: '',
			isbig: false,
		})
		const changeNews = i => {
			window.clearTimeout(sliderTimeout)
			newsuse.newsid = i
			sliderTimeout = setTimeout(function() {
				sliderT()
			}, 10000)
		}
		const newsLeft = computed(() => {
			if (store.state.isdesktop) return '-' + newsuse.newsid * newsuse.ww + 'px'
			else return '-' + newsuse.newsid * sliderdata.imgcw + 'px'
		})
		const textW = d => {
			if (!store.state.ismobile) {
				if (d.img === '') return '604px'
			}
		}
		const newsId = i => {
			if (newsuse.newsid === i) return ' active'
			else return ''
		}
		const sliderT = () => {
			if (loaded.value) {
				newsuse.newsid++
				if (newsuse.newsid === newsuse.news.content.length) newsuse.newsid = 0
			}

			sliderTimeout = setTimeout(function() {
				sliderT()
			}, 10000)
			loaded.value = true
		}
		const slideWidth = computed(() => {
			if (store.state.istablet) return sliderdata.imgcw + 'px'
			else return ''
		})
		const handleResize = () => {
			let els =
				sliderdata.imgC.currentStyle || window.getComputedStyle(sliderdata.imgC)
			let elsm = els.padding || els['padding-left']
			sliderdata.imgcw = sliderdata.imgC.clientWidth - parseInt(elsm) * 2
		}
		onBeforeMount(async () => {
			newsuse.news = await store.dispatch('postDatas', {
				url: 'news-list',
				data: {
					rp: 4,
					sortname: 'TARIH',
				},
			})
		})
		onMounted(() => {
			sliderdata.imgC = document.querySelector('.main-news-slider-C')
			let els =
				sliderdata.imgC.currentStyle || window.getComputedStyle(sliderdata.imgC)
			let elsm = els.padding || els['padding-left']
			sliderdata.imgcw = sliderdata.imgC.clientWidth - parseInt(elsm) * 2
			window.addEventListener('resize', handleResize)
			sliderT()
		})
		watch(
			() => store.state.lang,
			async () => {
				loaded.value = false
				newsuse.news = await store.dispatch('postDatas', {
					url: 'news-list',
					data: {
						rp: 4,
						sortname: 'TARIH',
					},
				})
				newsuse.news = Object.assign({}, newsuse.news)
				newsuse.newsid = 0
				loaded.value = true
			}
		)

		return {
			store,
			sliderT,
			newsuse,
			newsId,
			changeNews,
			newsLeft,
			textW,
			sliderdata,
			slideWidth,
		}
	},
}
</script>
<style>
.main-news-slider-C {
	position: absolute;
	left: 210px;
	bottom: 78px;
	width: 600px;
	padding: 48px 0 48px 42px;
	background-color: #00509fe7;
	transition: all 0.4s ease-in-out;
	z-index: 1999;
}

.main-news-slider-inner {
	color: var(--c-white);
	display: flex;
	width: 620px;
	overflow: hidden;
	transition: all 0.4s ease-in-out;
	margin-bottom: 16px;
}
.main-news-slider-items-C {
	position: relative;
	transition: all 0.4s ease-in-out;
	width: 100%;
	z-index: 99;
}
.main-news-slider-items-C .swiper-wrapper{
	display: flex;
}
.main-news-slider-item {
	cursor: pointer;
}
.news-slider-img {
	max-width: 250px;
	margin-right: 16px;
}
.news-slider-img img {
	max-width: 250px;
}
.news-slider-content {
	display: flex;
	flex-direction: column;
	width: 354px;
	position: relative;
	z-index: 9;
}
.main-news-slider-C:after,
.main-news-slider-C:before {
	content: '';
	position: absolute;
	left: 100%;
	background-color: #00509fe7;
	width: 100px;
}
.main-news-slider-C:after {
	top: 0;
	height: 100px;
	border-top-right-radius: 100%;
}
.main-news-slider-C:before {
	bottom: 0;
	width: 100px;
	height: calc(100% - 100px);
}
.news-slider-content span,
.news-slider-content h3 {
	width: 354px;
}
.news-slider-content .news-slider-text,
.news-slider-content .news-slider-date {
	font-size: 14px;
	color: #ffffffea;
}
.news-slider-content .news-slider-date {
	margin-bottom: 10px;
}
.main-news-all-news-C {
	display: flex;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	bottom: 16px;
	align-items: center;
}
.main-news-all-btn a {
	color: #fff;
	text-decoration: none;
	padding: 4px;
}
.main-news-all-btn a:hover {
	text-decoration: underline;
}
.news-slide-pagination {
	display: flex;
	padding: 12px;
	justify-content: flex-end;
	align-self: center;
}
.news-slide-pagination .swiper-pagination-bullet {
	background-color: #fff;
	margin: 0 6px;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	position: relative;
	cursor: pointer;
}
.news-slide-pagination .swiper-pagination-bullet-active:before {
	content: '';
	width: 14px;
	height: 14px;
	border: 1px solid #fff;
	border-radius: 100%;
	position: absolute;
	left: -3px;
	top: -3px;
}
</style>
