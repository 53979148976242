<template>
	<div class="r-col-1-4 m-col-12">
		<div class="footer-call-C">
			<div class="footer-call-img-C">
				<a :href="store.state.footer.footer_logo_1.left.url" :title="store.state.footer.footer_logo_1.left.txt">
					<img :src="store.state.footer.footer_logo_1.left.ico" :alt="store.state.footer.footer_logo_1.left.txt" />
				</a>
				<a :href="store.state.footer.footer_logo_1.right.url" :title="store.state.footer.footer_logo_1.right.txt">
					<img :src="store.state.footer.footer_logo_1.right.ico" :alt="store.state.footer.footer_logo_1.right.txt" />
				</a>
			</div>
			<div class="footer-call-img-C bottom-imgs">
				<a
					v-for="(logo, i) in store.state.footer.footer_logo_2.center"
					:key="i"
					:href="logo.url"
					:title="logo.txt"
				>
					<img :src="logo.ico" :alt="logo.txt" />
				</a>
			</div>
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
<style>
.footer-call-C {
	background-color: var(--c-primary-red);
	padding: 72px 0 32px 0;
	border-bottom-right-radius: 32px;
}
.footer-call-img-C {
	padding: 24px;
	border-bottom: 1px solid #ffffff77;
	text-align: center;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.footer-call-img-C:first-child {
	padding-top: 0;
}
.footer-call-img-C:last-child {
	border: none;
	padding-bottom: 0;
}
.footer-call-img-C.bottom-imgs {
	flex-direction: column;
}
.footer-call-img-C.bottom-imgs a {
	padding: 10px 0;
}
</style>
