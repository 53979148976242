<template>
	<div class="r-row r-mb-0" v-if="loaded">
		<div class="r-container r-pt-0 r-pb-0">
			<div class="main-project-tab-C flx-row r-col-12">
				<r-title title="projeler" />
				<div
					:class="[
						'tabs-C',
						'flx-row',
						store.state.ismobile ? '' : 'flx-no-shrink',
					]"
				>
					<div
						:class="['r-tab-item', 'no-trans', currtab === i ? ' active' : '']"
						:aria-selected="currtab === i ? ' true' : 'false'"
						v-for="(tab, i) in pdata.data.lists"
						:key="i"
						@click="changeTab(i)"
						@keydown="KeyDown($event,i)"
						tabindex="0"
						:role="$tt('aria_proje_tab_degistir')"
						:label="$tt('aria_proje_tab_degistir')"
					>
						<span>{{ tab.title }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="main-project-content-C flx-row jc-sbetween">
			<div
				class="main-project-item-C flx-col"
				v-for="(tabc, j) in newList"
				:key="j"
				:role="$tt('aria_tab_panel')"
				:aria-label="tabc.title"
			>
				<div
					class="main-project-img"
					:style="{ backgroundImage: 'url(' + tabc.img + ')' }"
				></div>
				<div class="main-project-txt flx-col">
					<h3>{{ tabc.title }}</h3>
					<p>
						{{ tabc.txt }}
					</p>
					<router-link :to="CreateLink(tabc.url, 'projeler')" :title="tabc.title">
						<r-btn :title="$tt('incele')" />
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref, reactive, onBeforeMount, watch, computed } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const loaded = ref(false)
		const currtab = ref(0)

		const pdata = reactive({
			data: {},
		})

		const newList = computed(() => {
			return pdata.data.lists[currtab.value].projects.slice(0, 5)
		})

		const changeTab = i => {
			currtab.value = i
		}
		const KeyDown = (e,i) => {
			if(e.key === 'Enter') changeTab(i)
		}
		onBeforeMount(async () => {
			pdata.data = await store.dispatch('postDatas', {
				url: 'project-list',
			})
			loaded.value = true
		})

		watch(
			() => store.state.lang,
			async () => {
				loaded.value = false
				pdata.data = await store.dispatch('postDatas', {
					url: 'project-list',
				})
				pdata.data = Object.assign({}, pdata.data)
				loaded.value = true
			}
		)

		return {
			store,
			pdata,
			currtab,
			loaded,
			changeTab,
			KeyDown,
			newList,
		}
	},
}
</script>
<style>
.r-tab-item {
	color: var(--c-txt-grey);
	font-size: 14px;
	font-weight: 600;
	position: relative;
	display: flex;
	cursor: pointer;
}
.r-tab-item span {
	position: relative;
	padding: 0 16px 10px 16px;
}
.r-tab-item:hover,
.r-tab-item.active {
	border-bottom: 2px solid var(--c-primary);
	color: var(--c-primary);
}
.r-tab-item:hover span:before,
.r-tab-item.active span:before {
	top: 100%;
	left: calc(50% - 12px);
	content: '';
	height: 12px;
	width: 24px;
	position: absolute;
	pointer-events: none;
	background-color: #004e9d;
}
.r-tab-item:hover:before,
.r-tab-item.active:before {
	top: calc(100% + 2px);
	left: calc(50% - 12px);
	content: '';
	height: 12px;
	width: 12px;
	position: absolute;
	pointer-events: none;
	background-color: #ffffff;
	z-index: 9;
	border-top-right-radius: 12px;
}
.r-tab-item:hover:after,
.r-tab-item.active:after {
	top: calc(100% + 2px);
	left: 50%;
	content: '';
	height: 12px;
	width: 12px;
	position: absolute;
	pointer-events: none;
	background-color: #ffffff;
	z-index: 9;
	border-top-left-radius: 12px;
}
.main-project-content-C {
	margin-top: 32px;
	width: 1400px;
}
.main-project-item-C {
	border-right: 1px solid #d5d5d5;
	padding: 90px 44px;
	display: flex;
	flex-direction: column;
}
.main-project-txt {
	margin-bottom: 36px;
}
.main-project-item-C .r-btn {
	align-self: flex-start;
}
.main-project-item-C:nth-child(even) {
	padding-top: 190px;
	flex-direction: column-reverse;
}
.main-project-item-C:nth-child(odd) .r-btn {
	border-top-left-radius: 0;
}
.main-project-item-C:nth-child(even) .r-btn {
	border-bottom-right-radius: 0;
}
.main-project-img {
	width: 100%;
	overflow: hidden;
	width: 200px;
	height: 330px;
	align-self: center;
	margin-bottom: 16px;
	background-position: center;
	background-size: cover;
}
</style>
