<template>
	<div :class="'main-menu-C' + isMenuOpen" v-if="store.state.menuloaded">
		<nav-main-img
			v-if="!store.state.ismobile && store.state.menus.options !== undefined"
		/>
		<nav-main-root
			v-if="store.state.menus.main !== undefined"
			:data="store.state.menus.main"
		/>
	</div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import NavMainImg from './NavMainImg'
import NavMainRoot from './NavMainRoot'
export default {
	components: {
		NavMainImg,
		NavMainRoot,
	},
	setup() {
		const store = useStore()
		const isMenuOpen = computed(() => {
			if (store.state.menuopened) return ' active'
			else return ''
		})
		return { isMenuOpen, store }
	},
}
</script>
<style>
.main-menu-C {
	display: none;
	position: absolute;
	width: calc(100% - 203px);
	height: 100%;
	background-color: #fff;
	left: 134px;
	pointer-events: all;
}
.main-menu-C.active {
	display: flex;
}
</style>
