import { inject } from 'vue'
import * as store from '@/store'
export default {
	install: app => {
		const CreateSlug = (app.config.globalProperties.CreateSlug = s => {
			s = s = ToTurkishLower(s)
			return s.replace(/ /g, '-').toLowerCase()
		})
		const CreateSlugPage = (app.config.globalProperties.CreateSlugPage = slug => {
			const $tt = inject('$tt')
			let s = $tt(slug)
			s = ToTurkishLower(s)
			return s.replace(/ /g, '-').toLowerCase()
		})
		const ToUpperCase = (app.config.globalProperties.ToUpperCase = s => {
			s = s.toString()
			var letters = {
				i: 'İ',
				ı: 'I',
				ş: 'Ş',
				ğ: 'Ğ',
				ü: 'Ü',
				ç: 'Ç',
				ö: 'Ö',
			}
			s = s.replace(/(([iışğüçö]))/g, function(letter) {
				return letters[letter]
			})
			return s.toUpperCase()
		})
		const ToTurkishLower = (app.config.globalProperties.ToTurkishLower = s => {
			s = s.toString()
			var letters = {
				İ: 'i',
				I: 'i',
				Ş: 's',
				Ğ: 'g',
				Ü: 'u',
				Ö: 'o',
				Ç: 'c',
				ı: 'i',
				ş: 's',
				ğ: 'g',
				ü: 'u',
				ç: 'c',
				ö: 'o',
			}
			s = s.replace(/(([İIŞĞÜÇÖışğüçö]))/g, function(letter) {
				return letters[letter]
			})
			return s
		})
		const HtmlDecode = (app.config.globalProperties.HtmlDecode = data => {
			var doc = new DOMParser().parseFromString(data, 'text/html')			
			var mapObj = { '&lt;': '<', '&gt;': '>' }

			var re = new RegExp(Object.keys(mapObj).join('|'), 'gi')
			doc.body.innerHTML = doc.body.innerHTML.replace(re, function(matched) {
				return mapObj[matched]
			})
			return doc.body.innerHTML
		})
		const CreateLink = (app.config.globalProperties.CreateLink = (
			page,
			root,
			param
		) => {
			let lang = store.default.state.lang === 'tr' ? '/' : '/en/'
			return (
				lang +
				(root !== undefined ? CreateSlugPage(root) + '/' : '') +
				(page !== '' ? page : '') +
				(param !== undefined ? '/' + param : '')
			)
		})
		app.provide('CreateSlug', CreateSlug)
		app.provide('CreateSlugPage', CreateSlugPage)
		app.provide('ToUpperCase', ToUpperCase)
		app.provide('HtmlDecode', HtmlDecode)
		app.provide('CreateLink', CreateLink)
	},
}
