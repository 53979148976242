<template>
	<div id="quick-menu-C" :role="$tt('aria_hizli_menuler')">
		<ul
			class="fast-ico-menu-C"
			v-if="store.state.menus.quick !== undefined && store.state.menuloaded"
		>
			<li
				v-for="(menu, i) in store.state.menus.quick.links"
				:key="i"
				@click="store.state.quickopened = false"
				@keydown="KeyDown($event)"
				:role="$tt('aria_hizli_menu')"
			>
				<a
					class="footer-link"
					v-if="menu.out === 'true' || menu.target === '_blank'"
					:href="menu.url"
					:target="menu.target"
				>
					<img class="fast-menu-icon" :src="menu.ico" :alt="menu.txt" />
					<div class="over-btn">
						<img :src="menu.icoo" :alt="menu.txt" />
						<span>{{ menu.txt }}</span>
					</div>
				</a>
				<router-link
					v-else
					:to="CreateLink(menu.url)"
					:title="menu.txt"
					tabindex="0"
				>
					<img class="fast-menu-icon" :src="menu.ico" :alt="menu.txt" />
					<div class="over-btn">
						<img :src="menu.icoo" :alt="menu.txt" />
						<span>{{ menu.txt }}</span>
					</div>
				</router-link>
			</li>
		</ul>
	</div>
	<ul class="left-menu-scroll" v-if="store.state.isdesktop">
		<li>
			<img src="@/assets/img/ico-hizli-menu-scroll.png" />
		</li>
	</ul>
</template>
<script>
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()
		const KeyDown = e => {
			if (e.key === 'Enter') store.state.quickopened = false
		}
		return {
			store,
			KeyDown,
		}
	},
}
</script>
