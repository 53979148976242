<template>
	<div
		:class="['r-title-C', 'jc-sbetween', small ? 'r-mt-16' : '']"
		:style="{ width: (small ? '96' : '100') + '%' }"
	>
		<span class="r-title-txt" v-if="small">
			{{ trans ? $tt(title) : title }}
		</span>
		<h2 v-else class="r-mb-0">{{ trans ? $tt(title) : title }}</h2>
		<span class="r-title-line" v-if="line"></span>
		<div class="plus-ico-C" v-if="plus">
			<div class="plus-btn-C flx-col">
				<div :class="['plus-btn-ico', active ? 'active' : '']"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: String,
		trans: {
			type: Boolean,
			default: true,
		},
		small: {
			Type: Boolean,
			default: true,
		},
		line: {
			type: Boolean,
			default: true,
		},
		plus: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
<style>
.r-title-C {
	display: flex;
	justify-content: center;
	align-self: flex-end;
	position: relative;
	align-self: auto;
	margin-right: 12px;
}
.r-title-C .r-title-txt {
	color: var(--c-txt-grey);
	font-weight: 800;
	font-size: 14px;
	position: relative;
	flex-shrink: 0;
}
.r-title-C .r-title-line {
	border-top: 1px solid var(--c-txt-grey);
	margin-left: 10px;
	align-self: center;
	width: 98%;
}
.r-title-C h2 {
	flex-shrink: 0;
	width: auto;
}
.plus-ico-C {
	display: flex;
	justify-content: center;
}
.plus-btn-C {
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-left: 16px;
}
.plus-btn-C span {
	font-size: 12px;
	color: #ccd0d4;
}
.plus-btn-ico {
	width: 60px;
	height: 60px;
	border: 1px solid #ccd0d4;
	margin-bottom: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.plus-btn-ico:before,
.plus-btn-ico:after {
	content: '';
	position: absolute;
	width: 30px;
	border-top: 2px solid var(--c-primary);
}
.plus-btn-ico:after {
	transition: all 0.4s ease-in-out;
	transform: rotate(90deg);
}
.plus-btn-ico.active:after {
	transform: rotate(0deg);
}
.plus-btn-C:hover span {
	color: var(--c-primary-red);
}
.plus-btn-C:hover .show-more-btn-ico,
.plus-btn-C:hover .show-more-btn-ico:before,
.plus-btn-C:hover .show-more-btn-ico:after {
	border-color: var(--c-primary-red);
}
@media only screen and (max-width: 640px) {
	.r-title-C .r-title-line {
		display: none;
	}
	.r-title-C h2 {
		flex-shrink: unset;
	}
}
</style>
