<template>
	<div class="r-col-5 t-col-12 jc-sbetween flx-col">
		<div class="main-director-C">
			<router-link
				class="director-item-C"
				v-for="(dir, i) in store.state.maindata.directors"
				:key="i"
				:to="CreateLink(dir.url, 'sayfa', '2')"
				:title="dir.titr + ' ' + dir.name"
			>
				<img :src="dir.img" class="director-img" :alt="dir.titr + ' ' + dir.name"/>
				<h4>{{ dir.name }}</h4>
				<span>{{ dir.titr }}</span>
			</router-link>
		</div>
		<div class="main-map-title-C r-lh-1" v-if="store.state.ismapok">
			<h1 class="r-nop">
				<span class="c-red">{{ $tt('ana_kesfet') }}</span>
				<span v-html="$tt('demiryollari_harita_goruntule')"></span>
			</h1>
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
<style>
.main-director-C {
	display: flex;
}
.director-item-C {
	margin: 10px 20px;
	cursor: pointer;
}
.director-item-C h4 {
	margin-bottom: 0;
}
.director-item-C span {
	color: var(--c-txt-grey);
	font-size: 11px;
}
.main-map-title-C {
	margin-top: 24px;
}
</style>
