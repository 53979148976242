<template>
	<div class="main-map-dist-C r-row r-row-in">
		<div class="r-col-7">
			<img src="@/assets/img/map.jpg" class="w-100" :alt="$tt('harita')"/>
		</div>
		<div class="r-col-5 t-col-12">
			<div class="main-map-cat-C flx-col jc-sbetween">
				<h3 class="c-white">{{ $tt('hizli_bakis') }}</h3>
				<div class="map-cat-C" :role="$tt('aria_harita_kategoriler')">
					<router-link
						v-for="(cat, i) in store.mapcats.cats"
						:key="i"
						class="map-cat-item"
						:to="GoRoute(cat)"
						:role="$tt('aria_harita_kategori')"
						:title="cat.title"
					>
						<img :src="cat.ico" :alt="cat.title"/>
						<span>{{ cat.title }}</span>
					</router-link>
				</div>
				<router-link
					class="map-cat-item as-end"
					:to="GoMap()"
					:title="$tt('harita')"
				>
					<img src="@/assets/img/ico-search-s-w.png" />
					<span>{{ $tt('buyuk_haritada_goruntule') }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import { inject } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()
		const $tt = inject('$tt')
		const GoRoute = u => {
			var lang = store.state.lang === 'tr' ? '' : 'en/'
			return '/' + lang + $tt('harita').toLowerCase() + '/' + u.slug
		}
		const GoMap = () => {
			var lang = store.state.lang === 'tr' ? '' : 'en/'
			return '/' + lang + $tt('harita').toLowerCase()
		}
		return {
			store,
			GoRoute,
			GoMap
		}
	},
}
</script>
<style>
.main-map-cat-C {
	background-color: var(--c-primary);
	padding: 36px 26px 16px 44px;
	display: flex;
	height: 100%;
	color: var(--c-white);
}
.map-cat-C {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
}
.map-cat-item {
	width: 50%;
	font-size: 14px;
	margin: 7px 0;
	align-items: center;
	display: flex;
	cursor: pointer;
	color: #fff;
}
.map-cat-item img {
	margin-right: 16px;
	width: 16px;
}
@media only screen and (max-width: 768px) {
	.main-map-cat-C {
		padding: 16px;
	}
}
@media only screen and (max-width: 480px) {
	.map-cat-item {
		width: 100%;
	}
}
</style>
