export default {
	install: (app, options) => {
		var messages = options.messages
		var locale = options.locale

		const $tt = (app.config.globalProperties.$tt = key => {
			return messages[locale][key]
		})
		const $tol = (app.config.globalProperties.$tol = key => {
			let lv = locale === 'tr' ? 'en' : 'tr'
			return messages[lv][key]
		})
		const $SetLang = (app.config.globalProperties.$setLang = lang => {
			locale = lang
		})
		app.provide('$tt', $tt)
		app.provide('$tol', $tol)
		app.provide('$SetLang', $SetLang)
	},
}
