<template>
	<nav-logo v-if="store.state.isdesktop" />
	<nav-quick-menu v-if="!isMap" />
	<nav-map v-if="isMap" />
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import NavLogo from './NavLogo'
import NavQuickMenu from './NavQuickMenu'
import NavMap from './NavMap'
export default {
	components: {
		NavLogo,
		NavQuickMenu,
		NavMap,
	},
	setup() {
		const route = new useRoute()
		const store = useStore()

		const isMap = computed(() => {
			if (route.meta.name === 'harita' || route.meta.name === 'map') return true
			else return false
		})
		return {
			store,
			route,
			isMap,
		}
	},
}
</script>
