<template>
	<div
		class="main-menu-img-C"
		:style="{
			backgroundImage: 'url(' + opt.img + ')',
		}"
	>
		<router-link
			class="menu-map-btn"
			:to="GoMap()"
			v-if="store.state.isdesktop"
			:title="$tt('aria_harita')"
		>
			<img src="@/assets/img/ico-map-btn.png" :alt="$tt('aria_harita')"/>
			<div class="map-btn-inner">
				<h3
					class="c-white"
					v-if="opt.pop !== undefined && opt.pop.title !== undefined"
				>
					{{ opt.pop.title }}
				</h3>
				<span v-if="opt.pop !== undefined && opt.pop.txt !== undefined">
					{{ opt.pop.txt }}
				</span>
			</div>
		</router-link>
	</div>
</template>
<script>
import { inject } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const $tt = inject('$tt')
		const opt = store.state.menus.options
		const GoMap = () => {
			var lang = store.state.lang === 'tr' ? '' : 'en/'
			return '/' + lang + $tt('harita').toLowerCase()
		}
		return {
			opt,
			store,
			GoMap
		}
	},
}
</script>
<style>
.menu-map-btn {
	position: absolute;
	background-color: var(--c-primary-red);
	color: var(--c-white);
	display: flex;
	left: 50px;
	bottom: 48px;
	font-size: 12px;
	padding-left: 38px;
	align-items: center;
	cursor: pointer;
}
.menu-map-btn:hover,
.menu-map-btn:hover .map-btn-inner:after {
	background-color: var(--c-primary);
}
.menu-map-btn img {
	margin-right: 12px;
}
.map-btn-inner {
	padding: 42px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 130px;
}
.map-btn-inner h3,
.map-btn-inner span {
	width: 170px;
	z-index: 9;
}
.map-btn-inner:after {
	content: '';
	position: absolute;
	background-color: var(--c-primary-red);
	width: 50px;
	height: 100%;
	border-top-right-radius: 50px;
	top: 0;
	left: 100%;
	z-index: 1;
}
</style>
