<template>
	<div
		:class="['menus-C left-menu-C', isMap ? 'left-map-menu-C' : '']"
		v-if="showme"
	>
		<nav-left v-if="store.state.menuloaded" />
	</div>
	<div class="menus-C right-menu-C">
		<nav-right v-if="store.state.menuloaded" />
	</div>
	<nav-main v-if="store.state.menuloaded" />
</template>
<script>
import { onBeforeMount, watch, computed, ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import NavLeft from './NavLeft'
import NavRight from './NavRight'
import NavMain from './NavMain'

export default {
	components: {
		NavLeft,
		NavRight,
		NavMain,
	},
	setup() {
		const store = useStore()
		const route = useRoute()
		const intCount = ref(0)
		const $SetLang = inject('$SetLang')

		const LoadMenu = async () => {
			$SetLang(route.meta.lang)
			store.state.menus = await store.dispatch('postDatas', { url: 'menu' })
		}
		const isMap = computed(() => {
			if (route.meta.name === 'harita' || route.meta.name === 'map') return true
			else return false
		})
		const showme = computed(() => {
			if (store.state.isdesktop) return true
			else if (store.state.istablet) {
				if (store.state.quickopened) return true
				else return false
			} else {
				return false
			}
		})
		onBeforeMount(async () => {
			LoadMenu()
			var menuInt = setInterval(async () => {
				if (
					store.state.menus !== undefined &&
					store.state.menus !== null &&
					Object.keys(store.state.menus).length > 0
				) {
					clearInterval(menuInt)
					store.state.menuloaded = true
				} else {
					intCount.value++
					if (intCount.value >= 120) clearInterval(menuInt)
				}
			}, 50)
		})
		watch(
			() => store.state.lang,
			async () => {
				LoadMenu()
				var menuInt = setInterval(async () => {
					if (
						store.state.menus !== undefined &&
						store.state.menus !== null &&
						Object.keys(store.state.menus).length > 0
					) {
						clearInterval(menuInt)
						store.state.menuloaded = true
					} else {
						intCount.value++
						if (intCount.value >= 120) clearInterval(menuInt)
					}
				}, 50)
			}
		)
		return {
			store,
			isMap,
			showme,
		}
	},
}
</script>
<style>
.right-menu-C {
	right: 0;
	width: 69px;
	background-color: var(--c-primary);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.bottom-menu-C {
	right: 128px;
	top: auto !important;
	bottom: 78px;
	display: flex;
	justify-content: flex-end;
	height: auto !important;
}
.nav-logo-tablet {
	position: absolute;
	left: 0;
	width: auto !important;
	top: 0;
	margin-top: 12px !important;
	margin-left: 24px;
}
.nav-logo-tablet img {
	height: 70px;
}
.left-menu-C.left-map-menu-C {
	width: 420px;
	justify-content: flex-start;
}
.left-menu-C.left-map-menu-C .logo-C {
	justify-content: flex-start;
	text-align: left;
	padding-left: 10px;
}
.main-menu-img-C {
	background-repeat: no-repeat;
	width: 45%;
	background-size: cover;
	background-position: center;
}
.top-menu-C {
	top: 36px !important;
	right: 128px;
	display: flex;
	height: auto !important;
}
.top-menu-C ul {
	display: flex;
	list-style: none;
}
.top-menu-C ul li {
	padding-left: 45px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	align-self: center;
	cursor: pointer;
}
.main-slider-C .top-menu-C ul li a {
	color: var(--c-white);
}
.sub-page.top-menu-C {
	justify-content: flex-end;
	padding: 20px 134px;
	border-bottom: 1px solid #e4e1e1;
	background-color: #f5f5f5;
}
.sub-page.top-menu-C ul li {
	color: #656b71;
}
.top-menu-C ul li:hover {
	color: var(--c-primary-red);
}
@media only screen and (max-width: 1024px) {
	.main-menu-C {
		left: 0;
		width: calc(100% - 69px);
	}
	.main-menu-img-C {
		width: 20%;
	}
	.main-menu-left-C {
		width: 40%;
		padding-left: 50px;
	}
	.main-menu-right-C {
		width: 40%;
		padding-left: 50px;
	}
	.bottom-menu-C {
		right: 100px;
		bottom: 24px;
	}
	.logo-C {
		width: auto !important;
	}
	.top-menu-C {
		position: relative !important;
		top: 0;
		right: 0;
		align-items: flex-start !important;
		height: auto !important;
	}
	.top-menu-C ul li {
		padding-left: 0 !important;
	}
	.top-menu-C a {
		color: var(--c-menu);
		font-size: 20px;
		font-weight: 400;
	}
	.menus-C.bottom-menu-C,
	.sub-page.top-menu-C,
	.main-slider-C .top-menu-C {
		display: none;
	}
	.main-menu-C {
		left: 0;
		top: 69px;
		width: 100%;
	}
	.main-menu-img-C {
		width: 10%;
	}
	.main-menu-left-C,
	.main-menu-right-C {
		width: 45%;
		padding-top: 30px;
		padding-left: 30px;
	}
	.main-menu-C .menu-item {
		padding-bottom: 32px;
	}
	.main-menu-right-C .menu-item {
		font-size: 20px;
		padding-bottom: 20px;
	}
	.right-menu-C {
		width: 100%;
		height: 69px !important;
		flex-direction: row !important;
		justify-content: space-between !important;
		padding: 0 24px;
	}
	.main-menu-icon {
		margin: inherit;
	}
	.main-menu-left-C .social-menu-C {
		display: flex;
		max-width: 80%;
		justify-content: space-between;
	}
	.main-menu-left-C .social-menu-C li {
		align-items: center;
		justify-content: center;
		display: flex;
		margin-top: 44px;
	}
	.main-menu-left-C .lang-btn {
		color: #fff;
	}
	.map-menu-content-C {
		background-color: #fff;
		height: 100%;
	}
	.map-menu-title h1 {
		font-size: 24px;
	}
	.map-menu-title h1 br {
		display: none;
	}
}
@media only screen and (max-width: 640px) {
	.main-menu-left-C {
		width: 45%;
		overflow-y: scroll;
		padding-left: 20px;
		padding-bottom: 60px;
	}
	.main-menu-right-C {
		width: 55%;
		overflow-y: scroll;
		padding-bottom: 60px;
		padding-left: 16px;
		max-height: calc(100vh - 70px);
	}
	.main-menu-right-C .menu-sub-C {
		margin-left: 0;
	}
	.main-menu-right-C .menu-item {
		font-size: 16px;
		padding-bottom: 20px;
	}
	.main-menu-C .menu-item {
		font-size: 16px;
		padding-bottom: 20px;
	}
	.main-menu-left-C .social-menu-C {
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 8px;
		width: 100%;
		background-color: var(--c-primary);
		z-index: 99;
		max-width: 100%;
	}
	.main-menu-left-C .social-menu-C li {
		margin-top: 0;
	}
	.left-menu-C.left-map-menu-C {
		width: 100%;
	}
}
</style>
