<template>
	<div
		:class="[
			'search-box-C',
			pos === 'side' ? 'side-search' : '',
			pos === 'opened' ? 'opened-search' : '',
			pos === 'map' ? 'map-search' : '',
		]"
		@click="clickBox"
	>
		<div class="search-box-ico" @click="searchClick" @keyDown="KeySearch($event)" tabindex="0" :aria-label="$tt('aria_ara_buton')"></div>
		<div class="search-box-inp-C" v-if="store.state.searchShow">
			<input
				type="text"
				class="search-inp"
				v-model="q"
				:placeholder="placeholder || $tt('ara_placeholder')"
				@focus="focusIn"
				@blur="focusOut"
				@keyup="enterSearch"
				:aria-label="$tt('aria_ara_input')"
				tabindex="0" 
			/>
		</div>
	</div>
</template>
<script>
import { inject, ref } from 'vue'
import { useStore } from 'vuex'
export default {
	props: {
		page: {
			type: Boolean,
			default: true,
		},
		pos: String,
		placeholder: String,
	},
	setup(props) {
		const store = useStore()
		const focused = ref(false)
		const $tt = inject('$tt')
		const q = ref('')
		const searchClick = () => {
			store.state.shareShow = false
			if (store.state.searchShow) {
				if (q.value !== '') {
					store.dispatch('openUrl', {
						root: $tt('arama'),
						query: { q: q.value },
					})
				}
				if (props.pos !== 'opened') {
					store.state.searchShow = false
					q.value = ''
				}
			} else {
				store.state.searchShow = true
				setTimeout(() => {
					document.querySelector('.search-inp').focus()
				}, 50)
			}
		}
		const KeySearch = e => {
			if(e.key === 'Enter') searchClick()
		}
		const focusIn = () => {
			focused.value = true
		}
		const focusOut = () => {
			focused.value = false
		}
		const enterSearch = e => {
			if (focused.value && q.value !== '' && e.key === 'Enter') searchClick()
		}
		const closeSearch = () => {
			if (props.pos !== 'opened' && props.pos !== 'map')
				store.state.searchShow = false
		}
		if (props.pos === 'opened') store.state.searchShow = true
		if (props.pos === 'map') store.state.searchShow = true
		document.addEventListener('click', closeSearch)
		const clickBox = e => {
			e.stopPropagation()
		}
		return {
			store,
			searchClick,
			clickBox,
			closeSearch,
			q,
			focusIn,
			focusOut,
			enterSearch,
			KeySearch
		}
	},
}
</script>
<style>
.search-box-C {
	margin-left: 32px;
	position: relative;
	padding: 3px;
	border-top-right-radius: 66px;
	background-color: #ffffff;
	height: 66px;
	z-index: 9;
}
.search-box-ico {
	width: 60px;
	height: 60px;
	border-top-right-radius: 66px;
	background-color: var(--c-white);
	background-image: url('../assets/img/ico-ara.png');
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: 33%;
	cursor: pointer;
}
.search-box-ico:hover {
	background-color: var(--c-primary);
	background-image: url('../assets/img/ico-ara-w.png');
}
.search-box-inp-C {
	position: absolute;
	right: 66px;
	top: 0;
	background-color: var(--c-white);
	padding: 3px;
}
.search-inp {
	min-width: 320px;
	height: 60px;
	background-color: var(--c-white);
	border: none;
	padding: 20px;
	padding: 0;
}
.search-box-C.side-search {
	margin-left: 0;
	border-top-right-radius: 0;
	background-color: transparent;
}
.search-box-C.side-search .search-box-ico {
	background-color: transparent;
	background-image: url('../assets/img/ico-ara-w.png');
}
.search-box-C.side-search .search-box-inp-C {
	padding-left: 10px;
	right: 69px;
	border: 1px solid var(--c-primary);
}
.search-box-C.opened-search {
	flex-direction: row-reverse;
	width: 50%;
	display: flex;
	margin-left: 0;
}
.search-box-C.opened-search .search-box-inp-C {
	position: relative;
	width: 100%;
	right: 0;
}
.search-box-C.opened-search .search-box-inp-C .search-inp {
	width: 100%;
}
.search-box-C.map-search {
	flex-direction: row-reverse;
	width: 100%;
	display: flex;
	margin-left: 0;
}
.search-box-C.map-search .search-box-inp-C {
	position: relative;
	width: 100%;
	right: 0;
}
.search-box-C.map-search .search-box-inp-C .search-inp {
	width: 100%;
	border-bottom: 1px solid #e8eaeb;
}
@media only screen and (max-width: 640px) {
	.search-inp {
		min-width: 250px !important;
	}
}
</style>
