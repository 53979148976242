<template>
	<footer v-if="loaded" class="flx-col">
		<footer-logos :data="store.state.footer.logos" />
		<footer-content />
	</footer>
</template>
<script>
import { useStore } from 'vuex'
import { ref, onBeforeMount, watch } from 'vue'
import FooterLogos from './FooterLogos'
import FooterContent from './FooterContent'

export default {
	components: {
		FooterLogos,
		FooterContent,
	},
	setup() {
		const store = useStore()
		const loaded = ref(false)

		onBeforeMount(async () => {
			store.state.footer = await store.dispatch('postDatas', {
				url: 'footer',
			})
			loaded.value = true
		})
		watch(
			() => store.state.lang,
			async () => {
				loaded.value = false
				store.state.footer = await store.dispatch('postDatas', {
					url: 'footer',
				})
				store.state.footer = Object.assign({}, store.state.footer)
				loaded.value = true
			}
		)

		return {
			store,
			loaded,
		}
	},
}
</script>
<style>
footer {
	display: flex;
	width: 100%;
}
.footer-content-C {
	background-color: var(--c-primary);
	font-size: 12px;
}
@media only screen and (max-width: 768px) {
	.footer-content-C {
		font-size: 16px;
	}
	.footer-content-C.r-nop {
		padding: 32px !important;
	}
	.footer-content-C .r-container {
		flex-direction: column;
	}
	.footer-col {
		width: 50% !important;
	}
	.footer-all-links-C {
		flex-wrap: wrap;
	}
	.footer-call-C {
		position: relative;
		right: auto;
		left: -32px;
	}
}
@media only screen and (max-width: 640px) {
	.footer-col {
		padding-right: 10px !important;
	}
	.footer-col:nth-child(even) {
		padding-right: 0 !important;
	}
}
</style>
