<template>
	<a :href="OpenPage" class="lang-btn">
		<span>{{ $tt('language') }}</span>
	</a>
</template>
<script>
import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
	setup() {
		const route = useRoute()
		const store = useStore()
		const $tol = inject('$tol')
		const CreateSlugPage = inject('CreateSlugPage')
		const CreateSlug = inject('CreateSlug')
		const OpenPage = computed(() => {
			var root = ''
			switch (route.meta.name) {
				case CreateSlugPage('haberler'):
					root = CreateSlug($tol('haberler'))
					break
				case CreateSlugPage('projeler'):
					root = CreateSlug($tol('projeler'))
					break
				case CreateSlugPage('iletisim'):
					root = CreateSlug($tol('iletisim'))
					break
				case CreateSlugPage('muzeler'):
					root = CreateSlug($tol('muzeler'))
					break
				case CreateSlugPage('basin_odasi'):
					root = CreateSlug($tol('basin_odasi'))
					break
				case CreateSlugPage('duyurular'):
					root = CreateSlug($tol('duyurular'))
					break
				case CreateSlugPage('raillife'):
					root = CreateSlug($tol('raillife'))
					break
				default:
					root = ''
			}
			let url = (store.state.lang === 'tr' ? '/en/' : '/') + root
			return url
		})

		return {
			OpenPage,
		}
	},
}
</script>
