<template>
	<div class="r-row">
		<div class="r-container r-pt-0 r-pb-0 flx-col">
			<main-map-district v-if="loaded" />
			<main-map-cats v-if="loaded" />
		</div>
	</div>
</template>
<script>
import { ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import MainMapDistrict from './MainMapDistrict'
import MainMapCats from './MainMapCats'

export default {
	components: {
		MainMapDistrict,
		MainMapCats,
	},
	setup() {
		const store = useStore()
		const loaded = ref(false)

		onBeforeMount(async () => {
			store.mapcats = await store.dispatch('postDatas', {
				url: 'map-cats',
			})
			loaded.value = true
		})
		return {
			loaded,
		}
	},
}
</script>
