<template>
	<div class="main-menu-left-C" :role="$tt('aria_ana_menu_sol')">
		<template v-for="(menu, i) in data" :key="i">
			<div
				v-if="menu.sub !== undefined && menu.sub.length > 0"
				@click="MainMenuClick(i)"
				@keydown="MainMenuKey($event,i)"
				:class="['menu-item', mainSelected === i ? 'active' : '']"
				tabindex="0"
				:title="menu.txt"
			>
				{{ menu.txt }}
			</div>
			<template v-else>
				<a
					v-if="menu.out === 'true' || menu.target === '_blank'"
					:class="['menu-item']"
					:href="menu.url"
					:target="menu.target"
					tabindex="0"
					:title="menu.txt"
				>
					{{ menu.txt }}
				</a>
				<router-link v-else :to="MakeUrl('', menu)" :class="['menu-item']" tabindex="0" :title="menu.txt" >
					<span @click="OpenPage">{{ menu.txt }}</span>
				</router-link>
			</template>
		</template>
		<switch-lang v-if="store.state.istablet" />
		<nav-social :data="store.state.menus.socials" v-if="store.state.istablet" />
	</div>
	<div class="main-menu-right-C" :role="$tt('aria_ana_menu_sag')">
		<template v-if="mainSelected !== null && data[mainSelected].sub.length > 0">
			<template v-for="(sub, j) in data[mainSelected].sub" :key="j">
				<div
					v-if="sub.sub !== undefined && sub.sub.length > 0"
					@click="subSelected = j"
					@keydown="subSelected = j"
					:class="['menu-item', subSelected === j ? 'active' : '']"
					tabindex="0"
					:title="sub.txt"
				>
					{{ sub.txt }}
					<div class="menu-sub-C" v-if="j === subSelected">
						<template v-for="(subm, k) in sub.sub" :key="k">
							<a
								v-if="subm.out === 'true' || subm.target === '_blank'"
								:class="['menu-item']"
								:href="subm.url"
								:target="subm.target"
								tabindex="0"
								:title="subm.txt"
							>
								{{ subm.txt }}
							</a>
							<router-link
								v-else
								:to="MakeUrl(data[mainSelected].url, subm)"
								:class="['menu-item']" tabindex="0"
								:title="subm.txt"
							>
								<span @click="OpenPage">{{ subm.txt }}</span>
							</router-link>
						</template>
					</div>
				</div>
				<template v-else>
					<a
						v-if="sub.out === 'true' || sub.target === '_blank'"
						:class="['menu-item']"
						:href="sub.url"
						:target="sub.target"
						:title="sub.txt"
						tabindex="0"
					>
						{{ sub.txt }}
					</a>
					<router-link
						v-else
						:to="MakeUrl(data[mainSelected].url, sub)"
						:class="['menu-item']"
						tabindex="0"
						:title="sub.txt"
					>
						<span @click="OpenPage">{{ sub.txt }}</span>
					</router-link>
				</template>
			</template>
		</template>
	</div>
</template>
<script>
import { useStore } from 'vuex'
import { inject, ref } from 'vue'
import NavSocial from './NavSocial'
import SwitchLang from './SwitchLang'
import router from '@/router'
export default {
	components: {
		NavSocial,
		SwitchLang,
	},
	props: {
		data: Object,
	},
	setup(props) {
		const store = useStore()
		const mainSelected = ref(null)
		const subSelected = ref(null)
		const $tt = inject('$tt')
		const MakeUrl = (r, m) => {
			let lang = store.state.lang === 'tr' ? '/' : '/en/'
			let root = r === '' ? '' : r + '/'
			if (r !== '') {
				if (!router.hasRoute(r)) root = $tt('sayfa') + '/'
			} else {
				if (!router.hasRoute(m.url)) root = $tt('sayfa') + '/'
			}
			let path = lang + root + m.url
			if (m.sub !== undefined && m.sub.length > 0) {
				let id = 0
				let isOut = true
				for (let i = 0; i < m.sub.length; i++) {
					if (m.sub[i].out !== 'true' && m.sub[i].target !== '_blank') {
						id = i
						isOut = false
						break
					}
				}
				if (!isOut) path = lang + root + m.sub[id].url
			}
			return path
		}
		const OpenPage = () => {
			if (
				mainSelected.value !== null &&
				(props.data[mainSelected.value].url === 'projeler' ||
					props.data[mainSelected.value].url === 'projects')
			) {
				store.state.projectcatid = subSelected.value
			}
			store.state.submenu = []
			store.state.shareShow = false
			store.state.menuopened = false
			mainSelected.value = null
			subSelected.value = null
		}
		const MainMenuClick = i => {
			mainSelected.value = i
			subSelected.value = null
		}
		const MainMenuKey = (e,i) => {
			if(e.key === 'Enter') MainMenuClick(i)
		}
		return {
			mainSelected,
			subSelected,
			MakeUrl,
			OpenPage,
			store,
			MainMenuClick,
			MainMenuKey
		}
	},
}
</script>
<style>
.main-menu-C .menu-item {
	color: var(--c-menu);
	font-weight: 400;
	padding-bottom: 16px;
	padding-right: 16px;
	font-size: 20px;
	position: relative;
	cursor: pointer;
}
.main-menu-C .menu-item:hover,
.main-menu-C .menu-item.active {
	color: var(--c-white);
}
.main-menu-left-C {
	background-color: var(--c-primary);
	width: 25%;
	padding-top: 110px;
	padding-left: 150px;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.main-menu-left-C .menu-item.active:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: '';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(0, 79, 159, 0);
	border-left-color: #004f9f;
	border-width: 14px;
	margin-top: -20px;
}
.main-menu-right-C {
	background-color: var(--c-secondary);
	width: 30%;
	padding-top: 110px;
	padding-left: 100px;
	display: flex;
	flex-direction: column;
	overflow: auto;
}
.main-menu-right-C .menu-item {
	font-size: 14px;
	padding-bottom: 10px;
}
.main-menu-right-C .menu-sub-C {
	margin-top: 20px;
	margin-left: 36px;
	margin-bottom: 46px;
	border-left: 1px solid #388adf;
	display: flex;
	flex-direction: column;
	padding-left: 16px;
}
</style>
