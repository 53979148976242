<template>
	<div
		:class="['r-row', 'main-places-row', store.state.istablet ? 'r-nop' : '']"
	>
		<div
			class="main-place-item"
			:style="{ width: 100 / store.state.maindata.places.length + '%' }"
			v-for="(place, i) in store.state.maindata.places"
			:key="i"
		>
			<div class="main-places-C h-100">
				<div class="flx-col h-100">
					<h2 class="c-white">{{ place.title }}</h2>
					<p class="c-white h-100">
						{{ place.txt }}
					</p>
					<div class="main-places-btn-C flx-row flx-wrap">
						<template v-for="(btn, j) in place.buttons" :key="j">
							<a
								:href="btn.url"
								:target="btn.target"
								v-if="btn.out === 'true' || btn.target === '_blank'"
								class="places-btn"
							>
								{{ btn.txt }}
							</a>
							<router-link v-else :to="CreateLink(btn.url)">
								<r-btn class="places-btn" :title="btn.txt" />
							</router-link>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
<style>
.main-places-row {
	padding-left: 139px !important;
	padding-right: 69px !important;
	margin-bottom: 0 !important;
}
.main-place-item {
	background-size: cover;
}
.main-place-item:nth-child(odd) .main-places-C {
	background-color: #d00612e0;
}
.main-place-item:nth-child(even) .main-places-C {
	background-color: #004f9de0;
}
.main-place-item:nth-child(1) {
	background-image: url('../../assets/img/img-main-llimanlar.jpg');
}
.main-place-item:nth-child(2) {
	background-image: url('../../assets/img/img-main-lojistik-merkezleri.jpg');
}
.main-places-C {
	display: flex;
	flex: 1 1 auto;
}
.main-place-item .main-places-C {
	padding: 60px;
}
.main-places-txt {
	width: 100%;
}
.r-btn.places-btn {
	background-color: transparent;
	border: 1px solid #fff;
	border-radius: 20px;
	font-size: 14px;
	padding: 10px 20px;
	font-weight: 600;
	width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 14px 14px 0 0;
}
.r-btn.places-btn:hover {
	background-color: var(--c-primary-red);
	border: 1px solid var(--c-primary-red);
}
.main-place-logistics .r-btn.places-btn:hover {
	background-color: var(--c-primary);
	border: 1px solid var(--c-primary);
}
.places-btn:first-child {
	border-top-right-radius: 0;
}
.places-btn:last-child {
	border-top-left-radius: 0;
}
</style>
