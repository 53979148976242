<template>
	<div class="r-col-1-4 footer-col" v-for="(grp, i) in newMenuData" :key="i">
		<div class="footer-links-C flx-col" v-for="(menu, j) in grp" :key="j">
			<template v-if="menu.sub.length === 0">
				<router-link
					:to="MakeUrl('', menu)"
					class="footer-link footer-link-main"
				>
					{{ menu.txt }}
				</router-link>
			</template>
			<template v-else>
				<div class="footer-link footer-link-main">
					{{ menu.txt }}
				</div>
				<template v-for="(sub, k) in menu.sub" :key="k">
					<a
						class="footer-link"
						v-if="sub.out === 'true' || sub.target === '_blank'"
						:href="sub.url"
						:target="sub.target"
					>
						{{ sub.txt }}
					</a>
					<router-link v-else :to="MakeUrl(menu.url, sub)" class="footer-link">
						<span @click="SetProperties(menu.url, sub, k)">{{ sub.txt }}</span>
					</router-link>
				</template>
			</template>
		</div>
	</div>
</template>
<script>
import { computed, inject, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import router from '@/router'
export default {
	props: {
		data: Object,
	},
	setup(props) {
		const store = useStore()
		const route = useRoute()
		const footerData = ref(props.data)
		const $tt = inject('$tt')
		const CreateSlugPage = inject('CreateSlugPage')
		const CreateSlug = inject('CreateSlug')

		const newMenuData = computed(() => {
			var count = 0
			var colcount = 0
			var titcount = 0
			var maxc = 12
			var newA = []
			var tempArr = footerData.value
			newA[0] = []
			for (let i = 0; i < tempArr.length; i++) {
				if (tempArr[i].url === CreateSlugPage('muzeler')) tempArr[i].sub = []
				newA[colcount].push(tempArr[i])
				titcount = titcount + 2
				for (let j = 0; j < tempArr[i].sub.length; j++) {
					count++
				}
				if (titcount + count > maxc) {
					colcount++
					count = 0
					titcount = 0
					newA[colcount] = []
				}
			}
			return newA
		})
		const SetProperties = (r, m, id) => {
			if (r !== route.params.slug) store.state.submenu = []
			if (r === CreateSlug($tt('projeler'))) store.state.projectcatid = id
		}
		const MakeUrl = (r, m) => {
			let lang = store.state.lang === 'tr' ? '/' : '/en/'
			let root = r === '' ? '' : r + '/'
			let path = lang + root + m.url
			if (r !== '') {
				if (!router.hasRoute(r)) root = $tt('sayfa') + '/'
			} else {
				if (!router.hasRoute(m.url)) root = $tt('sayfa') + '/'
			}
			if (m.sub.length > 0) {
				let id = 0
				let isOut = true
				for (let i = 0; i < m.sub.length; i++) {
					if (m.sub[i].out !== 'true' && m.sub[i].target !== '_blank') {
						id = i
						isOut = false
						break
					}
				}
				if (!isOut) path = lang + root + m.sub[id].url
			}

			if (r === CreateSlugPage('projeler'))
				path = lang + CreateSlugPage('projeler')

			return path
		}
		return {
			newMenuData,
			store,
			MakeUrl,
			SetProperties,
		}
	},
}
</script>
