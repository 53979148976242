<template>
	<div class="r-col-9 t-col-12 footer-all-links-C flx-row jc-sbetween">
		<footer-links :data="store.state.footer.menus" />
		<footer-call />
	</div>
</template>
<script>
import { useStore } from 'vuex'
import FooterLinks from './FooterLinks'
import FooterCall from './FooterCall'

export default {
	components: {
		FooterLinks,
		FooterCall,
	},
	setup() {
		const store = useStore()

		return {
			store,
		}
	},
}
</script>
