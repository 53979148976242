<template>
	<teleport to="body">
		<div :class="['pop-video-C', isactive ? 'active' : '']" @click="closeMe"
		@keydown="KeyDown($event)"
		:aria-label="$tt('aria_kapat')" tabindex="0">
			<iframe
				v-if="videotype"
				class="video-frame"
				:src="videourl"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen
			></iframe>
			<video v-else class="video-frame" controls>
				<source :src="videourl" type="video/mp4" />
			</video>
		</div>
	</teleport>
</template>
<script>
import { computed } from 'vue'
export default {
	props: {
		videourl: String,
		isactive: Boolean,
	},
	setup(props, { emit }) {
		const videotype = computed(() => {
			if (
				props.videourl.includes('youtube') ||
				props.videourl.includes('youtu.be')
			)
				return true
			else return false
		})
		const closeMe = () => {
			emit('update:isactive', false)
		}
		const KeyDown = e => {
			if(e.key === 'Enter') closeMe()
		}
		return {
			closeMe,
			KeyDown,
			videotype,
		}
	},
}
</script>
<style>
.pop-video-C {
	width: 100%;
	background-color: rgba(0, 0, 0, 0.849);
	padding: 60px;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}
.video-frame {
	width: 100%;
	height: 100%;
	max-width: 1280px !important;
	max-height: 720px !important;
}
</style>
