<template>
	<div class="r-row r-mb-0 r-mt-0 main-video-row-C">
		<div class="r-container r-pb-0">
			<main-videos />
			<main-directors />
		</div>
	</div>
</template>
<script>
import MainVideos from './MainVideos'
import MainDirectors from './MainDirectors'
export default {
	components: {
		MainVideos,
		MainDirectors,
	},
	props: {
		data: Object,
	},
}
</script>
