<template>
	<div class="r-row footer-logos-C" :role="$tt('aria_alt_logolar')">
		<swiper
			:slides-per-view="slidePerView"
			:space-between="0"
			navigation
			role="logo"
		>
			<swiper-slide v-for="(logo, i) in data" :key="i" tabindex="0">
				<a :href="logo.url" target="_blank" :title="logo.txt" role="logo">
					<img :src="logo.ico" :alt="logo.txt" :title="logo.txt" />
				</a>
			</swiper-slide>
		</swiper>
	</div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import { computed } from 'vue'
SwiperCore.use([Navigation])
export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		data: Array,
	},
	setup() {
		const slidePerView = computed(() => {
			if (window.innerWidth <= 480) {
				return 1
			} else if (window.innerWidth <= 768) {
				return 5
			} else if (window.innerWidth <= 1024) {
				return 6
			} else {
				return 6
			}
		})
		return {
			slidePerView,
		}
	},
}
</script>
<style>
.footer-logos-C {
	display: flex;
	justify-content: center;
	align-items: center;
}
.footer-logos-C a {
	margin: 0 24px;
}
.footer-logos-C .swiper-slide {
	display: flex;
	justify-content: center;
}
.footer-logos-C .swiper-button-disabled {
	display: none;
}
.footer-logos-C .swiper-container {
	width: 90%;
	display: flex;
	justify-content: center;
}
</style>
