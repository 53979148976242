import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import RTitle from '@/components/RTitle'
import RBtn from '@/components/RBtn'
import SearchBox from '@/components/SearchBox'
import SelectBox from '@/components/SelectBox'
import BreadCrumb from '@/pages/SubPage/BreadCrumb'
import './assets/css/default.css'
import i18n from '@/plugins/i18nPlugin'
import global from '@/plugins/global'
import lang from './lang.json'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

library.add(fab)
library.add(fas)

createApp(App)
	.use(store)
	.use(router)
	.use(global)
	.use(i18n, {
		locale: 'tr',
		messages: lang,
	})
	.component('fa-icon', FontAwesomeIcon)
	.component('r-title', RTitle)
	.component('r-btn', RBtn)
	.component('search-box', SearchBox)
	.component('select-box', SelectBox)
	.component('bread-crumb', BreadCrumb)
	.mount('#app')
