<template>
	<div :class="'quick-menu-icon' + isMenuOpen" @click="openMainMenu()" 
	@keydown="KeyDown($event)" tabindex="0">
		<span class="bar bar1"></span>
		<span class="bar bar2"></span>
		<span class="bar bar3"></span>
		<span class="bar bar4"></span>
	</div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		function openMainMenu() {
			store.state.quickopened = !store.state.quickopened
			store.state.menuopened = false
		}
		const KeyDown = e => {
			if(e.key === 'Enter') openMainMenu()
		}
		const isMenuOpen = computed(() => {
			if (store.state.quickopened) return ' selected'
			else return ''
		})
		return { openMainMenu, isMenuOpen, KeyDown }
	},
}
</script>
<style>
.quick-menu-icon {
	width: 30px;
	height: 30px;
	position: relative;
	cursor: pointer;
}
.quick-menu-icon .bar {
	padding: 0;
	width: 30px;
	height: 2px;
	background-color: #fff;
	display: block;
	transition: all 0.4s ease-in-out;
	position: absolute;
}
.quick-menu-icon .bar1 {
	top: 0;
}

.quick-menu-icon .bar2,
.quick-menu-icon .bar3 {
	top: 6px;
}

.quick-menu-icon .bar3 {
	right: 0;
}

.quick-menu-icon .bar4 {
	top: 12px;
}

.quick-menu-icon.selected .bar1 {
	transform: rotate(45deg);
	height: 2px;
	width: 38px;
	top: 14px;
	left: -4px;
}

.quick-menu-icon.selected .bar3 {
	transform: rotate(45deg);
	height: 2px;
	background-color: transparent;
}

.quick-menu-icon.selected .bar2 {
	transform: rotate(-45deg);
	height: 2px;
	background-color: transparent;
}

.quick-menu-icon.selected .bar4 {
	transform: rotate(-45deg);
	height: 2px;
	width: 38px;
	top: 14px;
	left: -3px;
}
</style>
