<template>
	<div class="r-row main-online-C">
		<div class="r-container jc-sbetween">
			<div class="r-col-5 m-col-12">
				<h1 class="c-white">
					{{ store.state.maindata.services[0].title }}
				</h1>
				<p class="c-white">
					{{ store.state.maindata.services[0].txt }}
				</p>
			</div>
			<div class="r-col-5 m-col-12">
				<div class="main-online-btn-C">
					<template
						v-for="(btn, i) in store.state.maindata.services[0].buttons"
						:key="i"
					>
						<a
							:href="btn.url"
							:target="btn.target"
							v-if="btn.out === 'true' || btn.target === '_blank'"
							class="r-btn r-btn-top-left online-btn"
							:title="btn.txt"
						>
							<img :src="btn.ico" :alt="btn.txt" />
							<span>{{ btn.txt }}</span>
						</a>
						<router-link
							v-else
							:to="CreateLink(btn.url)"
							class="r-btn r-btn-top-left online-btn"
							:title="btn.txt"
						>
							<img :src="btn.ico" :alt="btn.txt" />
							<span>{{ btn.txt }}</span>
						</router-link>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { useStore } from 'vuex'

export default {
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
<style>
.main-online-C {
	background-image: url(../../assets/img/img-online-hizmet-z.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 0 !important;
}
.main-online-btn-C {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: 100%;
	align-content: center;
}
.r-btn.online-btn {
	background-color: #ffffff52;
	border: 1px solid #fff;
	border-radius: 20px;
	font-size: 14px;
	padding: 10px 20px;
	font-weight: 600;
	width: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 14px;
}
.r-btn.online-btn:hover {
	background-color: var(--c-primary);
	border: 1px solid var(--c-primary);
}
.online-btn:nth-child(1) {
	border-bottom-right-radius: 0;
}
.online-btn:nth-child(2) {
	border-bottom-left-radius: 0;
}
.online-btn:nth-child(3) {
	border-top-right-radius: 0;
}
.online-btn:nth-child(4) {
	border-top-left-radius: 0;
}
.r-btn.online-btn img {
	margin-right: 10px;
}
</style>
