<template>
	<div
		:class="[
			'slider-top-shadow',
			route.meta.name === 'muzeler' || route.meta.name === 'museums'
				? 'more-dark'
				: '',
		]"
	></div>
	<div class="main-slider-content-C">
		<template v-for="(slide, i) in store.state.maindata.slider" :key="i">
			<template
				v-if="
					slide.url !== undefined &&
						slide.url !== null &&
						slide.url !== '' &&
						slide.url !== '#'
				"
			>
				<router-link
					v-if="slide.out !== 'true'"
					:to="slide.url"
					:class="['main-slider-item', currid === i ? 'active' : '']"
					:style="{ backgroundImage: 'url(' + slide.img + ')' }"
				></router-link>
				<a
					v-else
					:href="slide.url"
					target="_blank"
					:class="['main-slider-item', currid === i ? 'active' : '']"
					:style="{ backgroundImage: 'url(' + slide.img + ')' }"
				></a>
			</template>
			<template v-else>
				<div
					:class="['main-slider-item', currid === i ? 'active' : '']"
					:style="{ backgroundImage: 'url(' + slide.img + ')' }"
				></div>
			</template>
		</template>
	</div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
export default {
	setup() {
		const route = useRoute()
		const store = useStore()
		const currid = ref(0)
		const sliderTimeout = ref(null)

		const sliderT = () => {
			currid.value++
			if (currid.value === store.state.maindata.slider.length) currid.value = 0

			sliderTimeout.value = setTimeout(function() {
				sliderT()
			}, 20000)
		}
		onMounted(() => {
			sliderTimeout.value = setTimeout(function() {
				sliderT()
			}, 20000)
		})
		return { currid, route, store }
	},
}
</script>
<style>
.main-slider-content-C {
	display: flex;
	width: 100%;
	height: 100%;
}
.slider-top-shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #00000050;
	z-index: 9;
	pointer-events: none;
	left: 0;
	top: 0;
}
.slider-top-shadow.more-dark {
	background-color: #000000ab;
}
.main-slider-item {
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	top: 0;
	left: 0;
	position: absolute;
	visibility: hidden;
	opacity: 0;
	transition: all 1s ease-in-out;
}
.main-slider-item.active {
	visibility: visible;
	opacity: 1;
}
</style>
