<template>
	<ul class="social-menu-C" :role="$tt('aria_sosyal_medya_liste')">
		<li v-for="soc in data" :key="soc.txt" :role="$tt('aria_sosyal_medya_link')">
			<a :href="soc.url" target="_blank" :title="soc.txt">
				<fa-icon :icon="['fab', soc.ico]" />
			</a>
		</li>
		<li v-if="store.state.istablet">
			<img src="@/assets/img/ico-paylas-w.png" @click="openShare" @keydown="KeyDown($event)" :alt="$tt('aria_paylas')" tabindex="0"/>
		</li>
	</ul>
	<social-share />
</template>
<script>
import { useStore } from 'vuex'
import SocialShare from '@/components/SocialShare'
export default {
	props: {
		data: Array,
	},
	components: {
		SocialShare,
	},
	setup() {
		const store = useStore()
		const openShare = () => {
			store.state.shareShow = !store.state.shareShow
		}
		const KeyDown = e => {
			if(e.key === 'Enter') openShare()
		}
		return {
			store,
			openShare,
			KeyDown
		}
	},
}
</script>
<style>
.social-menu-C {
	list-style: none;
}
.social-menu-C li {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	min-width: 40px;
	cursor: pointer;
}
.social-menu-C svg {
	color: #fff;
	font-size: 18px;
	line-height: 40px;
	width: 100%;
}
</style>
