<template>
	<div class="main-slider-C" :style="{ height: hh + 'px' }">
		<main-slider-items />
		<main-slider-news />
		<top-menu
			v-if="store.state.isdesktop || store.state.istabletv"
			:main="true"
		/>
		<main-bot-menu />
	</div>
</template>
<script>
import { ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import MainSliderItems from './MainSliderItems'
import MainSliderNews from './MainSliderNews'
import TopMenu from '../Menu/TopMenu'
import MainBotMenu from '../Menu/MainBotMenu'

export default {
	components: {
		MainSliderItems,
		MainSliderNews,
		TopMenu,
		MainBotMenu,
	},
	setup() {
		const store = useStore()
		const hh = ref(document.documentElement.clientHeight)

		const handleResize = () => {
			hh.value = document.documentElement.clientHeight
		}

		onBeforeMount(() => {
			window.addEventListener('resize', handleResize)
		})

		onBeforeUnmount(() => {
			window.removeEventListener('resize', handleResize)
		})
		return {
			hh,
			store,
		}
	},
}
</script>
<style>
.main-slider-C {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
</style>
