<template>
	<div class="breadcrumb-C">
		<a @click="goTo()" v-for="(bc, i) in data" :key="i"></a>
	</div>
</template>
<script>
export default {
	props: {
		data: Object,
	},
	setup() {
		const goTo = u => {
			console.log(u)
		}
		return {
			goTo,
		}
	},
}
</script>
