import MainPage from '@/pages/Main/MainPage.vue'
import RootPage from '@/RootPage'
import SubPageRoot from '@/pages/SubPage/SubPageRoot.vue'
import Page404 from '@/pages/SubPage/404.vue'
import Page403 from '@/pages/SubPage/403.vue'
import store from '@/store'

const routeren = [
	{
		path: '/en',
		name: 'RootPage En',
		component: RootPage,
		meta: { type: 'main', name: 'main', lang: 'en' },
		beforeEnter: () => {
			store.state.lang = 'en'
		},
		children: [
			{
				path: '',
				name: 'MainPage En',
				component: MainPage,
				meta: { type: 'main', name: 'main', lang: 'en' },
			},
			{
				path: '',
				name: 'allpages',
				component: SubPageRoot,
				meta: { type: 'main', name: 'page', lang: 'en', title: 'TCDD' },
				children: [
					{
						path: ':slug+',
						component: () =>
							import(
								/* webpackChunkName: "allpages" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'page',
							lang: 'en',
							title: 'TCDD',
						},
					},
				],
			},
			{
				path: 'page',
				name: 'page',
				component: SubPageRoot,
				redirect: '/en/404',
				meta: { type: 'main', name: 'page', lang: 'en', title: 'TCDD' },
				children: [
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "page" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: { type: 'sub', name: 'page', lang: 'en', title: 'TCDD' },
						children: [
							{
								path: ':type',
								component: () =>
									import(
										/* webpackChunkName: "page" */ '@/pages/SubPage/PageSub.vue'
									),
								meta: { type: 'sub', name: 'page', lang: 'en', title: 'TCDD' },
							},
						],
					},
				],
			},
			{
				path: 'news',
				name: 'news',
				component: SubPageRoot,
				meta: { type: 'main', name: 'news', lang: 'en', title: 'News' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "haberler" */ '@/pages/SubPage/News/NewsList.vue'
							),
						meta: { type: 'main', name: 'news', lang: 'en', title: 'News' },
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "haberler" */ '@/pages/SubPage/News/NewsPage.vue'
							),
						meta: { type: 'sub', name: 'news', lang: 'en', title: 'News' },
					},
				],
			},
			{
				path: 'announcements',
				name: 'announcements',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'announcements',
					lang: 'en',
					title: 'Announce',
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "announcements" */ '@/pages/SubPage/Announce/AnnounceList.vue'
							),
						meta: {
							type: 'main',
							name: 'announcements',
							lang: 'en',
							title: 'Announce',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "announcements" */ '@/pages/SubPage/Announce/AnnouncePage.vue'
							),
						meta: {
							type: 'sub',
							name: 'announcements',
							lang: 'en',
							title: 'Announce',
						},
					},
				],
			},
			{
				path: 'press-room',
				name: 'press-room',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: 'press-room',
					lang: 'en',
					title: 'Press Room',
				},
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "pressroom" */ '@/pages/SubPage/Press/PressList.vue'
							),
						meta: {
							type: 'main',
							name: 'press-room',
							lang: 'en',
							title: 'Press Room',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "pressroom" */ '@/pages/SubPage/Press/PressPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'press-room',
							lang: 'en',
							title: 'Press Room',
						},
					},
				],
			},
			{
				path: 'projects',
				name: 'projects',
				component: SubPageRoot,
				meta: { type: 'main', name: 'projects', lang: 'en', title: 'Projects' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "projects" */ '@/pages/SubPage/Project/ProjectsList.vue'
							),
						meta: {
							type: 'main',
							name: 'projects',
							lang: 'en',
							title: 'Projects',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "projects" */ '@/pages/SubPage/Project/ProjectPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'projects',
							lang: 'en',
							title: 'Projects',
						},
					},
				],
			},
			{
				path: 'organizational',
				name: 'organizational',
				component: SubPageRoot,
				redirect: '/en/404',
				meta: {
					type: 'main',
					name: 'organizational',
					lang: 'en',
					title: 'Organizational',
				},
				children: [
					{
						path: 'organization-chart',
						component: () =>
							import(
								/* webpackChunkName: "organizational" */ '@/pages/SubPage/OrganizationChart/PageOrganizationChart.vue'
							),
						meta: {
							type: 'sub',
							name: 'organizational',
							lang: 'en',
							title: 'Organizational',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "organizational" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'organizational',
							lang: 'en',
							title: 'Organizational',
						},
					},
				],
			},
			{
				path: 'contact',
				name: 'contact',
				component: SubPageRoot,
				meta: { type: 'main', name: 'contact', lang: 'en', title: 'Contact' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "contact" */ '@/pages/SubPage/Contact/PageContact.vue'
							),
						meta: {
							type: 'sub',
							name: 'contact',
							lang: 'en',
							title: 'Contact',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "contact" */ '@/pages/SubPage/PageSub.vue'
							),
						meta: {
							type: 'sub',
							name: 'contact',
							lang: 'en',
							title: 'Contact',
						},
					},
				],
			},
			{
				path: 'videos',
				name: 'videos',
				component: SubPageRoot,
				meta: { type: 'main', name: 'videos', lang: 'en', title: 'Videos' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "videos" */ '@/pages/SubPage/Video/PageVideoList.vue'
							),
						meta: { type: 'sub', name: 'videos', lang: 'en', title: 'Videos' },
					},
				],
			},
			{
				path: 'raillife',
				name: 'raillife',
				component: SubPageRoot,
				meta: { type: 'main', name: 'raillife', lang: 'en', title: 'Raillife' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "raillife" */ '@/pages/SubPage/Raillife/RaillifeList.vue'
							),
						meta: {
							type: 'sub',
							name: 'raillife',
							lang: 'en',
							title: 'Raillife',
						},
					},
				],
			},
			{
				path: 'map',
				name: 'map',
				component: () =>
					import(/* webpackChunkName: "map" */ '@/pages/Map/MapMain.vue'),
				meta: { type: 'main', name: 'map', lang: 'en', title: 'Map' },
				children: [
					{
						path: ':id',
						component: () =>
							import(
								/* webpackChunkName: "map" */ '@/pages/Map/MapMain.vue'
							),
						meta: { type: 'sub', name: 'map', lang: 'en', title: 'Map' },
					},
				],
			},
			{
				path: 'museums',
				name: 'museums',
				component: SubPageRoot,
				meta: { type: 'main', name: 'museums', lang: 'en', title: 'Museums' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "museums" */ '@/pages/SubPage/Museum/MuseumsList.vue'
							),
						meta: {
							type: 'main',
							name: 'museums',
							lang: 'en',
							title: 'Museums',
						},
					},
					{
						path: ':slug',
						component: () =>
							import(
								/* webpackChunkName: "museums" */ '@/pages/SubPage/Museum/MuseumPage.vue'
							),
						meta: {
							type: 'sub',
							name: 'museums',
							lang: 'en',
							title: 'Museums',
						},
					},
				],
			},
			{
				path: 'search',
				name: 'search',
				component: SubPageRoot,
				meta: { type: 'main', name: 'search', lang: 'en', title: 'Search' },
				children: [
					{
						path: '',
						component: () =>
							import(
								/* webpackChunkName: "search" */ '@/pages/SubPage/Search/PageSearch.vue'
							),
						meta: { type: 'sub', name: 'search', lang: 'en', title: 'Search' },
						props: r => ({ qu: r.query.q }),
					},
				],
			},
			{
				path: '404',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: '404',
					lang: 'en',
					title: '404 - Page Not Found',
				},
				children: [
					{
						path: '',
						component: Page404,
						meta: {
							type: 'main',
							name: '404',
							lang: 'en',
							title: '404 - Page Not Found',
						},
					},
				],
			},
			{
				path: '403',
				component: SubPageRoot,
				meta: {
					type: 'main',
					name: '403',
					lang: 'en',
					title: "403 - You don't have permission to access.",
				},
				children: [
					{
						path: '',
						component: Page403,
						meta: {
							type: 'main',
							name: '403',
							lang: 'en',
							title: "403 - You don't have permission to access.",
						},
					},
				],
			},
		],
	},
]
export default routeren
