<template>
	<div class="menus-C bottom-menu-C">
		<ul :role="$tt('aria_alt_linkler')">
			<li v-for="(bot, i) in store.state.menus.bot" :key="i" :role="$tt('aria_alt_link')">
				<a :href="bot.url" target="_blank" :title="bot.txt"><img :src="bot.ico" :alt="bot.txt"/></a>
			</li>
		</ul>
	</div>
</template>
<script>
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		return {
			store,
		}
	},
}
</script>
